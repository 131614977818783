import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

export default function ProductItemView({ nameFormatted, isSoldOut, priceFormatted, description }) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView className={classes.container} rounded shadowHover bg={themeColors.white75}>
      <div className={classes.row}>
        <FText heading h6 bold black>
          {nameFormatted}
        </FText>
        {isSoldOut ? (
          <FText h7 error bold>
            SOLD OUT
          </FText>
        ) : (
          <div className={classes.add}>
            <FText bold h7 black>
              ${priceFormatted}
            </FText>
          </div>
        )}
      </div>
      <div className={classes.description}>
        {!!description && (
          <>
            <FView h={1} bg={themeColors.grey} mb={'0.5rem'} />
            <FText h9 bold grey>
              {description}
            </FText>
          </>
        )}
      </div>
    </FView>
  )
}

const classes = {
  container: css({
    margin: 3,
    marginBottom: '1rem',
  }),
  row: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.8rem 1.5rem 0 1.5rem',
  }),
  description: css({
    padding: '.4rem 1.5rem .8rem 1.5rem',
  }),
  add: css({
    display: 'flex',
    alignItems: 'center',
  }),
}
