import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR } from 'styles'

const CancelButtonView = ({ fontSize }) => (
  <FontAwesomeIcon icon="times-circle" style={fontSize && { fontSize: fontSize }} className={styles.button} />
)

const styles = {
  button: css({
    color: COLOR.primary,
    cursor: 'pointer',
    fontSize: '2.1rem',
  }),
}

export default CancelButtonView
