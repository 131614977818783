import React, { useState, useContext, useRef } from 'react'
import { FView, FButton, FSelect, FInput, FModal, FText } from 'f-web-app/components'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'
import useReactRouter from 'use-react-router'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaCreditCard, FaMoneyBill } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function Cart() {
  const { themeColors, Cart, Components } = useContext(ThemeContext)
  const loading = useSelector(dispatch.restaurant.getLoading)
  const [orderCompleteId, setOrderCompleteId] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const nameInput = useRef()
  const phoneInput = useRef()
  const { history } = useReactRouter()
  const [deliveryAddressEditModalVisible, setDeliveryAddressEditModalVisible] = useState(false)
  const subTotalBeforeDiscount = useSelector(dispatch.user.getCartSubTotalBeforeDiscount)
  const orderType = useSelector(dispatch.user.getOrderType)
  const deliveryAddress = useSelector(dispatch.user.getDeliveryAddress)
  const deliveryTime = useSelector(dispatch.restaurant.getDeliveryTime)
  const deliveryUnit = useSelector(dispatch.user.getDeliveryUnit)
  const deliveryInstructions = useSelector(dispatch.user.getDeliveryInstructions)
  const deliveryAvailable = useSelector(dispatch.restaurant.getIsDeliveryAvailable)
  const deliveryMinimumSubTotal = useSelector(dispatch.restaurant.getDeliveryMinimumSubTotal)
  const deliveryDistance = useSelector(dispatch.restaurant.getDeliveryDistance)
  const restaurantLatLng = useSelector(dispatch.restaurant.getRestaurantLatLng)
  const hasPayment = useSelector(dispatch.user.getHasPayment)
  const deliveryOrderOpenDetails = useSelector(dispatch.restaurant.getDeliveryOrderOpenDetails)

  const isOnlineCardPaymentEnabled = useSelector(dispatch.restaurant.getIsOnlineCCEnabled)
  const isInpersonCashPaymentEnabled = useSelector(dispatch.restaurant.getIsInpersonCashEnabled)
  const isInpersonCardPaymentEnabled = useSelector(dispatch.restaurant.getIsInpersonCardEnabled)
  const isCashDeliveryEnabled = useSelector(dispatch.restaurant.getIsCashDeliveryEnabled)

  let isCashEnabled = false
  if (orderType === 'Delivery') {
    isCashEnabled = isCashDeliveryEnabled && isInpersonCashPaymentEnabled
  } else {
    isCashEnabled = isInpersonCashPaymentEnabled
  }

  const last4 = useSelector(dispatch.user.getLast4)
  const total = useSelector(dispatch.user.getCartTotal)
  const [paymentMethod, setPaymentMethod] = useState('online-creditcard')

  const _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // _handleButtonClick('save')
    }
  }

  const _renderOrderComplete = () => {
    return <Cart.OrderComplete orderId={orderCompleteId} />
  }

  const _handleUserDetailsSubmitPress = (e) => {
    e.preventDefault()
    let name = ''
    if (!dispatch.user.getName()) {
      name = nameInput.current.value.trim()
      if (!name) {
        dispatch.notification.setMessage({
          message: 'Name cannot be empty!',
          level: 'error',
        })
        return
      }
    }
    let phoneNumber = ''
    if (!dispatch.user.getPhoneNumber()) {
      phoneNumber = phoneInput.current.value.trim()
      if (!phoneNumber) {
        dispatch.notification.setMessage({
          message: 'Phone Number cannot be empty!',
          level: 'error',
        })
        return
      }
    }
    setShowModal(false)
    setSubmitting(true)
    dispatch.user
      .changeUserInfo({ name, phoneNumber })
      .then(() => {
        _handleConfirmOrderPress()
      })
      .catch((e) => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const _handleConfirmOrderPress = () => {
    if (!dispatch.restaurant.getIsStoreOpen()) {
      dispatch.notification.setMessage({
        message: 'Online ordering is closed!',
        level: 'error',
      })
      return
    }

    if (dispatch.user.getOrderType() == null) {
      dispatch.notification.setMessage({
        message: `You must select Pickup or Delivery.`,
        level: 'error',
      })
      return
    }

    if (dispatch.user.getOrderType() === 'Delivery') {
      const subTotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      const deliveryMinimum = dispatch.restaurant.getDeliveryMinimumSubTotal()
      if (subTotalBeforeDiscount < deliveryMinimum) {
        dispatch.notification.setMessage({
          message: `SubTotal must be at least $${deliveryMinimum} for delivery`,
          level: 'error',
        })
        return
      }
      const deliveryOrderOpenDetails = dispatch.restaurant.getDeliveryOrderOpenDetails()
      if (!deliveryOrderOpenDetails.isOpen) {
        if (deliveryOrderOpenDetails.openMoment) {
          dispatch.notification.setMessage({
            message: `Delivery will be available at ${deliveryOrderOpenDetails.openMoment.calendar()}`,
            level: 'error',
          })
        } else {
          dispatch.notification.setMessage({
            message: `Delivery is currently unavailable`,
            level: 'error',
          })
        }
        return
      }
    }

    const name = dispatch.user.getName()
    const phoneNumber = dispatch.user.getPhoneNumber()
    if (!name || !phoneNumber) {
      setShowModal(true)
      return
    }
    dispatch.user
      .createOrder({ paymentMethod })
      .then((order) => {
        dispatch.notification.setMessage({
          message: 'Your order has been processed!',
          level: 'success',
        })
        setOrderCompleteId(order.orderId)
      })
      .catch((e) => {
        dispatch.notification.setMessage({
          message: 'Placing Order Failed. Please try again with different payment method. ' + e.message,
          level: 'error',
        })
      })
  }

  const _renderSubmitButton = ({ isLoggedIn, isPlacingOrder, hasPayment, last4, total, isOnlinePayment }) => {
    if (!isLoggedIn) {
      return (
        <FButton onClick={() => history.push(`/auth/cart`)}>
          <Cart.LoginButtonView />
        </FButton>
      )
    }
    if (!hasPayment && isOnlinePayment) {
      return (
        <FButton onClick={() => history.push(`/payment/cart`)} disabled={isPlacingOrder}>
          <Cart.AddPaymentButtonView disabled={isPlacingOrder} />
        </FButton>
      )
    }
    return (
      <FButton onClick={() => _handleConfirmOrderPress()} disabled={isPlacingOrder}>
        {total === 0 ? (
          <Cart.PlaceFreeOrderButtonView disabled={isPlacingOrder} isPlacingOrder={isPlacingOrder} />
        ) : (
          <Cart.PlaceOrderButtonView
            disabled={isPlacingOrder}
            isPlacingOrder={isPlacingOrder}
            orderType={orderType ? orderType : ''}
            last4={last4}
          />
        )}
      </FButton>
    )
  }

  return (
    <>
      <Cart.CartView
        loading={loading}
        CartSummaryOrOrderCompleteElement={
          orderCompleteId == null ? (
            <FSelect selector={[dispatch.user.getIsCartEmpty, dispatch.user.getCartWaitTime]}>
              {([isCartEmpty, cartWaitTime]) => {
                return (
                  <Cart.SummaryContainerView
                    DeliveryOptionElement={
                      !deliveryAvailable ? null : (
                        <FView>
                          <FView row ml={'1rem'} mb="1rem">
                            <FView fill>
                              <FButton onClick={() => dispatch.user.setOrderType('Pickup')}>
                                <FView
                                  h={'5rem'}
                                  rounded
                                  bc={orderType === 'Pickup' ? themeColors.primary : themeColors.grey}
                                  bw={1}
                                  center
                                  mr={8}
                                >
                                  <FText h7 bold grey primary={orderType === 'Pickup'}>
                                    Pickup
                                  </FText>
                                </FView>
                              </FButton>
                            </FView>
                            <FView fill>
                              <FButton
                                onClick={() => {
                                  if (!deliveryOrderOpenDetails || !deliveryOrderOpenDetails.isOpen) {
                                    if (!deliveryOrderOpenDetails || !deliveryOrderOpenDetails.openMoment) {
                                      // Available at unknown time
                                      dispatch.notification.setMessage({
                                        message: `Delivery is currently unavailable`,
                                        level: 'error',
                                      })
                                    } else {
                                      dispatch.notification.setMessage({
                                        message: `Delivery will be available at ${deliveryOrderOpenDetails.openMoment.calendar()}`,
                                        level: 'error',
                                      })
                                    }
                                    return
                                  }
                                  dispatch.user.setOrderType('Delivery')
                                  if (!deliveryAddress) {
                                    setDeliveryAddressEditModalVisible(true)
                                  }
                                }}
                              >
                                <FView
                                  h={'5rem'}
                                  rounded
                                  bc={orderType === 'Delivery' ? themeColors.primary : themeColors.grey}
                                  bw={1}
                                  center
                                  ml={8}
                                >
                                  <FText h7 bold grey primary={orderType === 'Delivery'}>
                                    Delivery
                                  </FText>
                                  <FText h7 bold grey primary={orderType === 'Delivery'}>
                                    {!deliveryOrderOpenDetails || !deliveryOrderOpenDetails.isOpen
                                      ? ' (Unavailable)'
                                      : ''}
                                  </FText>
                                </FView>
                              </FButton>
                            </FView>
                          </FView>
                          {orderType === 'Delivery' && subTotalBeforeDiscount < deliveryMinimumSubTotal && (
                            <FView row alignCenter>
                              <FView size={'1rem'} />
                              <FText h7 bold primary>
                                SubTotal must be at least ${deliveryMinimumSubTotal} for delivery
                              </FText>
                            </FView>
                          )}
                          {orderType === 'Delivery' && (
                            <>
                              <FView ml="1rem">
                                <FView pv={10}>
                                  <FText h7 bold black>
                                    DELIVERY ADDRESS:
                                  </FText>
                                </FView>
                              </FView>
                              <FView h={1} bg={themeColors.lightGrey} />
                              <FView mh="1rem" mb={'2.5rem'} mt={'1.5rem'}>
                                <FView row justifyBetween alignCenter>
                                  <FText h7 bold numberOfLines={2} grey>
                                    {deliveryUnit ? `#${deliveryUnit} ` : ''}
                                    {deliveryAddress}
                                  </FText>
                                  <FView size={'1rem'} />
                                  <FButton onClick={() => setDeliveryAddressEditModalVisible(true)}>
                                    <FontAwesomeIcon
                                      icon="edit"
                                      style={{ fontSize: '2.4rem' }}
                                      color={themeColors.primary}
                                    />
                                  </FButton>
                                </FView>
                                {!!deliveryInstructions && (
                                  <FText h7 bold numberOfLines={2} grey>
                                    {deliveryInstructions}
                                  </FText>
                                )}
                              </FView>
                            </>
                          )}
                          <Cart.DeliveryModal
                            orderType={orderType}
                            isOpen={deliveryAddressEditModalVisible}
                            onRequestClose={(newAddress) => {
                              if (!deliveryAddress && !newAddress) {
                                dispatch.user.setOrderType('Pickup')
                              }
                              setDeliveryAddressEditModalVisible(false)
                            }}
                            deliveryDistance={deliveryDistance}
                            deliveryAddress={deliveryAddress}
                            deliveryUnit={deliveryUnit}
                            deliveryInstructions={deliveryInstructions}
                            restaurantLatLng={restaurantLatLng}
                          />
                        </FView>
                      )
                    }
                    isCartEmpty={isCartEmpty}
                    waitTime={orderType === 'Delivery' ? cartWaitTime + deliveryTime : cartWaitTime}
                    PaymentMethodElement={
                      total > 0 && (
                        <Cart.PaymentMethodView
                          OnlineCCButtonElement={
                            isOnlineCardPaymentEnabled && (
                              <FView row>
                                <FButton onClick={() => setPaymentMethod('online-creditcard')}>
                                  <FView
                                    w={130}
                                    h={50}
                                    br={8}
                                    bc={paymentMethod === 'online-creditcard' ? themeColors.primary : themeColors.grey}
                                    bw={1}
                                    center
                                    row
                                  >
                                    <FaCreditCard
                                      size={24}
                                      color={
                                        paymentMethod === 'online-creditcard' ? themeColors.primary : themeColors.grey
                                      }
                                    />
                                    <FView size={8} />
                                    <FView>
                                      <FText h8 bold grey primary={paymentMethod === 'online-creditcard'}>
                                        Credit/Debit
                                      </FText>
                                      {!!last4 && (
                                        <FText h9 grey primary={paymentMethod === 'online-creditcard'}>
                                          xxxx {last4}
                                        </FText>
                                      )}
                                    </FView>
                                  </FView>
                                </FButton>
                                {hasPayment && (
                                  <FView justifyCenter ml={15}>
                                    <Link to={`payment/user`}>
                                      <FText h8 bold grey>
                                        Change Card
                                      </FText>
                                    </Link>
                                  </FView>
                                )}
                              </FView>
                            )
                          }
                          InpersonCashButtonElement={
                            isCashEnabled && (
                              <FButton onClick={() => setPaymentMethod('inperson-cash')}>
                                <FView
                                  w={130}
                                  h={50}
                                  br={8}
                                  bc={paymentMethod === 'inperson-cash' ? themeColors.primary : themeColors.grey}
                                  bw={1}
                                  center
                                  row
                                >
                                  <FaMoneyBill
                                    size={24}
                                    color={paymentMethod === 'inperson-cash' ? themeColors.primary : themeColors.grey}
                                  />
                                  <FView size={8} />
                                  <FText h8 bold grey primary={paymentMethod === 'inperson-cash'}>
                                    Cash
                                  </FText>
                                </FView>
                              </FButton>
                            )
                          }
                          InpersonCCButtonElement={
                            isInpersonCardPaymentEnabled && (
                              <FButton onClick={() => setPaymentMethod('inperson-creditcard')}>
                                <FView
                                  w={130}
                                  h={50}
                                  br={8}
                                  bc={paymentMethod === 'inperson-creditcard' ? themeColors.primary : themeColors.grey}
                                  bw={1}
                                  center
                                  row
                                >
                                  <FaCreditCard
                                    size={24}
                                    color={
                                      paymentMethod === 'inperson-creditcard' ? themeColors.primary : themeColors.grey
                                    }
                                  />
                                  <FView size={8} />
                                  <FView>
                                    <FText h8 bold grey primary={paymentMethod === 'inperson-creditcard'}>
                                      Credit/Debit
                                    </FText>
                                  </FView>
                                </FView>
                              </FButton>
                            )
                          }
                        />
                      )
                    }
                    NotesInputElement={
                      <FSelect selector={dispatch.user.getNotes}>
                        {(notes) => (
                          <FInput multiline value={notes} onChange={_handleNotesChange} {...Cart.notesInputProps} />
                        )}
                      </FSelect>
                    }
                    SubmitButtonElement={
                      <FSelect
                        selector={{
                          isLoggedIn: dispatch.user.getIsLoggedIn,
                          isPlacingOrder: dispatch.user.getIsPlacingOrder,
                          hasPayment: dispatch.user.getHasPayment,
                          last4: dispatch.user.getLast4,
                          total: dispatch.user.getCartTotal,
                          isOnlinePayment: paymentMethod.startsWith('online'),
                        }}
                      >
                        {_renderSubmitButton}
                      </FSelect>
                    }
                  />
                )
              }}
            </FSelect>
          ) : (
            _renderOrderComplete()
          )
        }
      />
      <FModal isOpen={showModal}>
        <Cart.UserDetailsModalView
          NameInputElement={
            !dispatch.user.getName() && (
              <FInput ref={nameInput} maxLength="18" onKeyPress={_handleKeyPress} {...Cart.nameInputProps} />
            )
          }
          PhoneInputElement={
            !dispatch.user.getPhoneNumber() && (
              <FInput ref={phoneInput} maxLength="10" onKeyPress={_handleKeyPress} {...Cart.phoneInputProps} />
            )
          }
          CancelButtonElement={
            <FButton onClick={() => setShowModal(false)}>
              <Components.PaintedButtonView label="CANCEL" style={{ width: '15rem' }} black />
            </FButton>
          }
          SubmitButtonElement={
            <FButton onClick={_handleUserDetailsSubmitPress} disabled={submitting}>
              <Components.PaintedButtonView label="SUBMIT" disabled={submitting} style={{ width: '15rem' }} />
            </FButton>
          }
        />
      </FModal>
    </>
  )
}

const _handleNotesChange = (e) => {
  e.preventDefault()
  const { value } = e.target
  dispatch.user.setNotes(value)
}
