import { COLOR } from 'f-web-app/styles'

export { default as Auth } from './Auth'
export { default as ForgotPasswordButton } from './ForgotPasswordButton'
export { default as ForgotPasswordButtonView } from './ForgotPasswordButtonView'
export { default as GoBackButtonView } from './GoBackButtonView'
export { default as LoginButtonView } from './LoginButtonView'
export { default as SignupButtonView } from './SignupButtonView'
export { default as NextButtonView } from './NextButtonView'
export { default as AuthView } from './AuthView'
export { default as ForgotPasswordModalView } from './ForgotPasswordModalView'

export const emailInputProps = {
  placeholder: 'Enter Email',
  style: { border: '1px solid ' + COLOR.lightGrey },
}

export const passwordInputProps = {
  placeholder: 'Enter Password',
  style: { border: '1px solid ' + COLOR.lightGrey },
}

export const nameInputProps = {
  placeholder: 'Enter Name',
  style: { border: '1px solid ' + COLOR.lightGrey },
}

export const phoneInputProps = {
  placeholder: 'Enter Phone Number',
  style: { border: '1px solid ' + COLOR.lightGrey },
}
