import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      dispatch.user.getIsLoggedIn() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: `/auth${props.location.pathname}` }} />
      )
    }
  />
)

export default function Routes() {
  const { Home, Menu, Auth, Cart, Payment, User, Page404 } = useContext(ThemeContext)
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home.Home} />
        <Route path="/menu" component={Menu.Menu} />
        <Route path={['/auth/:nextRoute/:nextRoute2', '/auth/:nextRoute']} component={Auth.Auth} />
        <Route path="/cart" component={Cart.Cart} />
        <PrivateRoute path={['/payment/:nextRoute', '/payment']} component={Payment.Payment} />
        <PrivateRoute path="/user" component={User.User} />
        <Route component={Page404.Page404} />
      </Switch>
    </Router>
  )
}
