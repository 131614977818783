export const colors = {
  BG: '#f9f3ee',
  PRIMARY: '#ee5253',
  DISABLED: '#757575',
  LIGHT_DISABLED: '#BDBDBD',
  LIGHT_TEXT: '#aaa',
  XLIGHT_TEXT: '#eee',
  LIGHT_GREY: '#ddd',
  ALERT: '#d32f2f',
  LINK: '#4A90E2',

  LIGHT_BG: '#f9f3ee',
  GREY_MAIN: '#4A4A4A',
  // FOODLY THEME
  background: '#F8F8F8',
  backgroundText: '#010B19',
  primaryText: 'rgba(236, 85, 31, 1)',
  cardText: '#010B19',
  backgroundImageText: '#000',

  white: 'rgba(255, 255, 255, 1)',
  white75: 'rgba(255, 255, 255, .75)',
  white50: 'rgba(255, 255, 255, .50)',
  white25: 'rgba(255, 255, 255, .25)',
  white10: 'rgba(255, 255, 255, .10)',

  black: 'rgba(0, 0, 0, 1)',
  black75: 'rgba(0, 0, 0, .75)',
  black50: 'rgba(0, 0, 0, .50)',
  black25: 'rgba(0, 0, 0, .25)',
  black10: 'rgba(0, 0, 0, .10)',

  xLightGrey: 'rgba(216, 216, 216, 1)',

  lightGrey: 'rgba(151, 151, 151, 1)',
  lightGrey75: 'rgba(151, 151, 151, .75)',
  lightGrey50: 'rgba(151, 151, 151, .50)',
  lightGrey25: 'rgba(151, 151, 151, .25)',
  lightGrey10: 'rgba(151, 151, 151, .10)',

  grey: 'rgba(74, 74, 74, 1)',
  grey75: 'rgba(74, 74, 74, .75)',
  grey50: 'rgba(74, 74, 74, .50)',
  grey25: 'rgba(74, 74, 74, .25)',
  grey10: 'rgba(74, 74, 74, .10)',

  transparent: 'rgba(0, 0, 0, 0)',

  primary: 'rgba(236, 85, 31, 1)',
  primary75: 'rgba(236, 85, 31, .75)',
  primary50: 'rgba(236, 85, 31, .50)',
  primary25: 'rgba(236, 85, 31, .25)',
  primary10: 'rgba(236, 85, 31, .10)',

  secondary: 'rgba(254, 123, 76, 1)',
  secondary75: 'rgba(254, 123, 76, .75)',
  secondary50: 'rgba(254, 123, 76, .50)',
  secondary25: 'rgba(254, 123, 76, .25)',
  secondary10: 'rgba(254, 123, 76, .10)',

  success: 'rgba(33, 228, 150, 1)',
  success75: 'rgba(33, 228, 150, .75)',
  success50: 'rgba(33, 228, 150, .50)',
  success25: 'rgba(33, 228, 150, .25)',
  success10: 'rgba(33, 228, 150, .10)',

  warning: 'rgba(255, 186, 63, 1)',
  warning75: 'rgba(255, 186, 63, .75)',
  warning50: 'rgba(255, 186, 63, .50)',
  warning25: 'rgba(255, 186, 63, .25)',
  warning10: 'rgba(255, 186, 63, .10)',

  error: 'rgba(208, 2, 27, 1)',
  error75: 'rgba(208, 2, 27, .75)',
  error50: 'rgba(208, 2, 27, .50)',
  error25: 'rgba(208, 2, 27, .25)',
  error10: 'rgba(208, 2, 27, .10)',
}

// Shared between mobile, web, and admin
export const TYPES = {
  ANALYTICS: {
    ERROR: 'ANALYTICS/ERROR',
    SCREEN_CHANGE: 'ANALYTICS/SCREEN_CHANGE', // For non-redux react-navigation
    LOGIN_SUCCESS: 'ANALYTICS/LOGIN_SUCCESS',
    LOGIN_FAIL: 'ANALYTICS/LOGIN_FAIL',
    LOGOUT_SUCCESS: 'ANALYTICS/LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'ANALYTICS/LOGOUT_FAIL',
    REGISTER_SUCCESS: 'ANALYTICS/REGISTER_SUCCESS',
    REGISTER_FAIL: 'ANALYTICS/REGISTER_FAIL',
    ORDER_COMPLETE: 'ANALYTICS/ORDER_COMPLETE',
    ORDER_FAILED: 'ANALYTICS/ORDER_FAILED',

    // ADMIN
    USER_PROPERTIES_UPDATE: 'ANALYTICS/USER_PROPERTIES_UPDATE',
    START_ALERT: 'ANALYTICS/START_ALERT',
    STOP_ALERT: 'ANALYTICS/STOP_ALERT',
    STATUS_CHANGE: 'ANALYTICS/STATUS_CHANGE',
    MENU_UPDATE: 'ANALYTICS/MENU_UPDATE',
    CATEGORY_UPDATE: 'ANALYTICS/CATEGORY_UPDATE',
    CATEGORY_DELETE: 'ANALYTICS/CATEGORY_DELETE',
    CATEGORY_ORDER_UPDATE: 'ANALYTICS/CATEGORY_ORDER_UPDATE',
    PRODUCT_ORDER_UPDATE: 'ANALYTICS/PRODUCT_ORDER_UPDATE',
    PRODUCT_OPTION_ADD: 'ANALYTICS/PRODUCT_OPTION_ADD',
    PRODUCT_OPTION_DELETE_SUCCESS: 'ANALYTICS/PRODUCT_OPTION_DELETE_SUCCESS',
    PRODUCT_OPTION_DELETE_FAIL: 'ANALYTICS/PRODUCT_OPTION_DELETE_FAIL',
    PRODUCT_UPDATE: 'ANALYTICS/PRODUCT_UPDATE',
    PRODUCT_DELETE_SUCCESS: 'ANALYTICS/PRODUCT_DELETE_SUCCESS',
    PRODUCT_DELETE_FAIL: 'ANALYTICS/PRODUCT_DELETE_FAIL',
    REWARDS_UPDATE: 'ANALYTICS/REWARDS_UPDATE',
    ANALYTICS_APPLY: 'ANALYTICS/ANALYTICS_APPLY',
    ORDERING_IS_OPEN_UPDATE: 'ANALYTICS/ORDERING_IS_OPEN_UPDATE',
    WAIT_TIME_UPDATE: 'ANALYTICS/WAIT_TIME_UPDATE',
    HOURS_UPDATE: 'ANALYTICS/HOURS_UPDATE',
  },
}
