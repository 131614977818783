import { css } from 'emotion'

export { COLOR, SCREEN, MEDIA_QUERY } from 'f-web/src/styles'
export const TEXT = {
  content: css({
    fontFamily: 'Roboto, sans-serif',
  }),
  heading: css({
    fontFamily: 'Cinzel, serif',
  }),
  heading2: css({
    fontFamily: 'serif',
  }),
}
