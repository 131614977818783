import React, { useContext } from 'react'
import { ThemeContext } from 'f-web-app'

export default function PlaceOrderButtonView({ disabled, isPlacingOrder, orderType = '', last4 }) {
  const { Components } = useContext(ThemeContext)
  return (
    <Components.PaintedButtonView
      disabled={disabled}
      label={isPlacingOrder ? 'Processing...' : `Place ${orderType} Order`}
    />
  )
}
