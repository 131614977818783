export const facebook = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/facebook.png'
export const instagram = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/instagram.png'
export const twitter = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/twitter.png'
export const user = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/user.png'
export const foodlyLogo =
  'https://res.cloudinary.com/foodlytech/image/upload/c_scale,f_auto,w_512/v1592389787/foodly/Foodly_Logo_Large_Orange.png'
export const foodlySquareLogo =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/foodly-square-logo.png'
export const foodlyRoundLogo =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/foodly-round-logo.png'
