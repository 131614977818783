export default {
  RESTAURANT_ID: process.env.REACT_APP_RESTAURANT_ID,
  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
}

// const ENV = {
//   dev: {
//     RESTAURANT_ID: 'sushihowe',
//     SERVER_URL: 'https://foodly-server-staging.herokuapp.com',
//     STRIPE_KEY: 'pk_test_zk1e3tT5H0XXQEG7ks9t4iBw',
//   },
//   local: {
//     RESTAURANT_ID: 'sushihowe',
//     SERVER_URL: 'http://localhost:3002',
//     STRIPE_KEY: 'pk_test_zk1e3tT5H0XXQEG7ks9t4iBw',
//   },
//   staging: {
//     RESTAURANT_ID: 'sushihowe',
//     SERVER_URL: 'https://foodly-server-staging.herokuapp.com',
//     STRIPE_KEY: 'pk_test_zk1e3tT5H0XXQEG7ks9t4iBw',
//   },
//   production: {
//     RESTAURANT_ID: '',
//     SERVER_URL: '',
//     STRIPE_KEY: '',
//   },
// }

// const envSetting = process.env.REACT_APP_ENVIRONMENT
// export default ENV[envSetting]
