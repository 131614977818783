import React from 'react'
import { FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LocationSelectButtonView = ({ id, name, address }) => (
  <FView row alignCenter w="100%" mv="1rem" className={classes.button}>
    <FontAwesomeIcon icon="map-marker" className={classes.icon} />
    <FView column ml="2rem" fill>
      <FText h3 backgroundText>
        {name}
      </FText>
      <FText h3 backgroundText>
        {address}
      </FText>
    </FView>
    <FontAwesomeIcon icon="chevron-right" className={classes.icon} />
  </FView>
)

const classes = {
  button: css({
    borderRadius: '1rem',
    backgroundColor: 'rgba(151,151,151,.3)',
    padding: '1rem',
    cursor: 'pointer',
  }),
  icon: css({
    color: 'white',
    fontSize: '2.5rem',
  }),
}

export default LocationSelectButtonView
