import React, { useContext } from 'react'
import { css } from 'emotion'
import { FLink, FView, FText } from 'components'
import { hero, mobileApp } from 'images'
import { MEDIA_QUERY } from 'styles'
import { useMediaMinSM, useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function Hero() {
  const { Home } = useContext(ThemeContext)
  const isMinSM = useMediaMinSM()
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <FLink>
          <img src={mobileApp} alt="Mobile App" className={classes.mobile} />
        </FLink>
        <div className={classes.column}>
          <FText heading black textCenter bold h4 h3={isMinSM} h2={isMinLG}>
            {'Now Taking Online Orders'}
          </FText>
          <FView size={'1rem'} />
          <FView>
            <FText heading h6 h5={isMinSM} h4={isMinLG} textCenter black>
              {'Order Today and Receive Points for Free Items'}
            </FText>
            <FView size={'0.5rem'} />
          </FView>
          <FView size={'2rem'} />
          <FView row={isMinMD}>
            <Home.OrderNowButton heading textProps={{ h5: true }} />
            <FView size={'2rem'} />
            {/* <Home.DownloadAppButton link="http://onelink.to/sushihoweapp" heading textProps={{ h5: true }} /> */}
          </FView>
        </div>
      </div>
    </div>
  )
}

const classes = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    backgroundAttachment: 'fixed',
  }),
  mobile: css({
    objectFit: 'contain',
    '&:hover': {
      transform: 'translateX(4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    display: 'none',
    height: '40vh',
    [MEDIA_QUERY.MIN_MD]: {
      display: 'block',
    },
    [MEDIA_QUERY.MIN_LG]: {
      height: '60vh',
    },
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MEDIA_QUERY.MIN_MD]: {
      marginLeft: '4rem',
    },
  }),
  content: css({
    position: 'absolute',
    height: '45%',
    width: '100%',
    top: '34%',
    backgroundColor: 'rgba(255,255,255,0.70)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      top: '43%',
    },
  }),
}
