import * as api from '../../api'
import { get, isEmpty } from 'lodash'
import { isMobile } from 'f-core/src/config/firebase'
import { moment, isMobileBrowser } from 'f-utils'

export default ({ dispatch, getState, stripeKey, serverUrl }) => ({
  getPayment() {
    if (dispatch.restaurant.getPaymentProcessor() === 'Moneris') {
      const payments = getState().user.payments || []
      for (const payment of payments) {
        if (payment.type === 'Moneris') {
          return payment
        }
      }
      return null
    }
    return getState().user.payment
  },
  getHasPayment() {
    const payment = dispatch.user.getPayment()
    return !isEmpty(payment)
  },
  getLast4() {
    const payment = dispatch.user.getPayment()
    if (dispatch.restaurant.getPaymentProcessor() === 'Moneris') {
      if (payment && payment.maskedPan) {
        return payment.maskedPan.slice(-4) || ''
      }
      return ''
    }
    return get(getState(), 'user.payment.card.last4', '')
  },
  getExpiryDate() {
    const payment = dispatch.user.getPayment()
    if (dispatch.restaurant.getPaymentProcessor() === 'Moneris') {
      if (payment && payment.expdate) {
        return payment.expdate.slice(2, 4) + '/' + payment.expdate.slice(0, 2)
      }
      return ''
    }

    const month = get(getState(), 'user.payment.card.exp_month', 0)
    const year = get(getState(), 'user.payment.card.exp_year', 0)
    if (month && year) {
      return ('0' + month).slice(-2) + '/' + year.toString().slice(-2)
    }
    return ''
  },
  getPostal() {
    const payment = dispatch.user.getPayment()
    if (dispatch.restaurant.getPaymentProcessor() === 'Moneris') {
      if (payment && payment.avsZipcode) {
        return payment.avsZipcode
      }
      return ''
    }

    return get(getState(), 'user.payment.card.address_zip', '')
  },
  async addCreditCard(card) {
    const userToken = await dispatch.user.getUserToken()
    if (!userToken) throw new Error('User Token not valid')

    // Create a new card
    const response = await api.stripe.createCardWithStripe(card, stripeKey)
    const { last4, id, brand, exp_month, exp_year } = response.card

    // Create a new customer on server side
    const res = await api.server.createCustomerWithStripe(serverUrl, userToken, response.id)

    // Don't let the failure of generating Moneris Data Token stop us from adding a credit card
    try {
      // Server will add Moneris Data Token to the User document, so we don't have to
      await api.server.addCardToVaultWithCC({ card, serverUrl, authToken: userToken })
    } catch (e) {
      console.warn(e.message)
    }

    const payment = {
      card: { last4, id, brand, exp_month, exp_year },
      customerId: res.id,
    }

    const userId = dispatch.user.getUserId()
    return api.user.updateUser(userId, { payment })
  },
  async updateCreditCard(card) {
    const userToken = await dispatch.user.getUserToken()
    if (!userToken) throw new Error('User Token not valid')

    const response = await api.stripe.createCardWithStripe(card, stripeKey)
    const { last4, id, brand, exp_month, exp_year, address_zip } = response.card

    // Create a new customer on server side
    const res = await api.server.updateCustomerWithStripe(serverUrl, userToken, response.id)

    // Don't let the failure of generating Moneris Data Token stop us from adding a credit card
    try {
      // Server will add Moneris Data Token to the User document, so we don't have to
      await api.server.addCardToVaultWithCC({ card, serverUrl, authToken: userToken })
    } catch (e) {
      console.warn(e.message)
    }

    const payment = {
      card: { last4, id, brand, exp_month, exp_year, address_zip },
      customerId: res.id,
    }

    const userId = dispatch.user.getUserId()

    return api.user.updateUser(userId, { payment })
  },
  async createOrder({ paymentMethod }) {
    if (!paymentMethod) {
      throw new Error('You must select a payment method.')
    }
    if (!dispatch.user.getIsLoggedIn()) {
      throw new Error('Not Authenticated')
    }
    if (!dispatch.restaurant.getIsStoreOpen()) {
      throw new Error('Ordering is currently closed')
    }
    const orderType = dispatch.user.getOrderType()
    if (!orderType) {
      throw new Error('Please select Pickup or Delivery and try again.')
    }

    const isPickupAvailable = dispatch.restaurant.getIsPickupAvailable()
    const isDeliveryAvailable = dispatch.restaurant.getIsDeliveryAvailable()
    const isDineInAvailable = dispatch.restaurant.getIsDineInAvailable()
    if (orderType === 'Pickup' && !isPickupAvailable) {
      throw new Error('Pickup is not available for this restaurant')
    } else if (orderType === 'Delivery' && !isDeliveryAvailable) {
      throw new Error('Delivery is not available for this restaurant')
    } else if (orderType === 'DineIn' && !isDineInAvailable) {
      throw new Error('Dine-in is not available for this restaurant')
    }

    if (orderType === 'Delivery') {
      const cartSubTotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      const deliveryMinimum = dispatch.restaurant.getDeliveryMinimumSubTotal()
      if (cartSubTotalBeforeDiscount < deliveryMinimum) {
        throw new Error(`SubTotal must be at least $${deliveryMinimum} for delivery`)
      }
      const deliveryOrderOpenDetails = dispatch.restaurant.getDeliveryOrderOpenDetails()
      if (!deliveryOrderOpenDetails.isOpen) {
        if (deliveryOrderOpenDetails.openMoment) {
          throw new Error(`Delivery will be available at ${deliveryOrderOpenDetails.openMoment.calendar()}`)
        } else {
          throw new Error(`Delivery is currently unavailable`)
        }
      }
    }

    dispatch.user.setIsPlacingOrder(true)
    try {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const cartItems = dispatch.user.getCart()
      const notes = dispatch.user.getNotes()
      const tipAmount = dispatch.user.getTipAmount()
      const deliveryAddress = dispatch.user.getDeliveryAddress()
      const deliveryUnit = dispatch.user.getDeliveryUnit()
      const deliveryInstructions = dispatch.user.getDeliveryInstructions()
      const validPromosWithDetails = dispatch.user.getValidPromosWithDetails()
      const cartWaitTime = dispatch.user.getCartWaitTime()
      // Remove everything from promos except id and count before sending to server
      const promos = Object.keys(validPromosWithDetails).reduce((prev, promoId) => {
        prev[promoId] = {
          id: promoId,
          count: validPromosWithDetails[promoId].count,
        }
        return prev
      }, {})
      const userToken = await dispatch.user.getUserToken()
      const {
        order: { orderNumber, pointsEarned, orderId, total },
      } = await api.server.createOrderAndCharge(serverUrl, userToken, restaurantId, locationId, {
        paymentMethod,
        cartItems,
        notes,
        orderType,
        tipAmount,
        deliveryAddress,
        deliveryUnit,
        deliveryInstructions,
        promos,
        sourceClient: isMobile ? 'mobile' : isMobileBrowser() ? 'mobile-web' : 'web',
      })
      dispatch.user._clearNotes()
      const orderDetails = {
        orderId,
        orderNumber,
        completionTime: moment().add(cartWaitTime, 'minutes'),
        cartItems,
        pointsEarned,
        promos,
        total,
      }

      return orderDetails
    } catch (error) {
      throw error
    } finally {
      dispatch.user.setIsPlacingOrder(false)
    }
  },
})
