import { useSelector } from 'react-redux'
import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import mapValues from 'lodash/mapValues'

export default function FSelect({ selector, selectorNot, children, ...props }) {
  const rootState = useSelector(state => state)

  let selectorState = rootState
  let selectorNotState = false

  if (selector != null) {
    // 2. If selector is a function, use selector function to return a slicedState
    if (isFunction(selector)) {
      selectorState = selector(rootState)
    }

    // 3. If selector is an object, use values as selector function to return slicedState object
    else if (isPlainObject(selector)) {
      selectorState = mapValues(selector, v => (isFunction(v) ? v(rootState) : v))
    }

    // 4. If selector is an array, use values as selector function to return slicedStates array
    else if (isArray(selector)) {
      selectorState = selector.map(v => (isFunction(v) ? v(rootState) : v))
    }

    // 5. Use selector as value
    else {
      selectorState = selector
    }
  }

  if (selectorNot != null) {
    // 6. If selectorNot is a function, use selectorNot function to return a slicedState
    if (isFunction(selectorNot)) {
      selectorNotState = selectorNot(rootState)
    }

    // 7. If selectorNot is an object, use values as selectorNot function to return slicedState object
    else if (isPlainObject(selectorNot)) {
      selectorNotState = mapValues(selectorNot, v => (isFunction(v) ? v(rootState) : v))
    }

    // 8. If selectorNot is an array, use values as selectorNot function to return slicedStates array
    else if (isArray(selectorNot)) {
      selectorNotState = selectorNot.map(v => (isFunction(v) ? v(rootState) : v))
    }

    // 9. Use selectorNot as value
    else {
      selectorNotState = selectorNot
    }
  }

  if (children) {
    if (isFunction(children)) {
      // Render Props
      return children(selectorState, props)
    } else {
      const stateTruthy = checkIfStateIsTruthy(selectorState)
      const stateNotTruthy = checkIfStateNotIsFalsy(selectorNotState)

      if (stateTruthy && stateNotTruthy) {
        return children
      } else {
        return null
      }
    }
  }
  // Self closing tag
  return selectorState === undefined ? null : selectorState
}

const checkIfStateIsTruthy = state => {
  // Conditional Render (render only if all selector object values are truthy)
  if (isPlainObject(state)) {
    const values = Object.values(state)
    for (const val of values) {
      if (!val) {
        return false
      }
    }
    return true
  }
  // Conditional Render (render only if all selector array values are truthy)
  if (isArray(state)) {
    for (const val of state) {
      if (!val) {
        return false
      }
    }
    return true
  }
  // Coditional Render only if selector is truthy
  return !!state
}

const checkIfStateNotIsFalsy = state => {
  // Conditional Render (render only if all selector object values are truthy)
  if (isPlainObject(state)) {
    const values = Object.values(state)
    for (const val of values) {
      if (val) {
        return false
      }
    }
    return true
  }
  // Conditional Render (render only if all selector array values are truthy)
  if (isArray(state)) {
    for (const val of state) {
      if (val) {
        return false
      }
    }
    return true
  }
  // Coditional Render only if selector is truthy
  return !state
}
