import React from 'react'
import { css } from 'emotion'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export default function FModal({ children, isOpen, closeModal, style = {} }) {
  return (
    <ReactModal
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: 0,
          border: 0,
          backgroundColor: 'transparent',
          transform: 'translate(-50%, -50%)',
          borderRadius: '1rem',
          boxShadow: '6px 6px 6px 0px rgba(0, 0, 0, .5)',
          ...style,
        },
      }}
      contentLabel="Modal"
      overlayClassName={classes.overlay}
      bodyOpenClassName={classes.body}
    >
      <div className={classes.container}>{children}</div>
    </ReactModal>
  )
}

const classes = {
  container: css({
    overflowY: 'auto',
  }),
  overlay: css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .4)',
    zIndex: 99,
  }),
  body: css({
    overflowY: 'hidden',
  }),
}
