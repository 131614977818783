export * from 'f-core/src/images'
export * from 'f-web-app/images'

export const foodGallery =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good,w_1220/sushihowe/foodGallery.jpg'
export const hero = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/hero.jpg'
export const locationMap =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/locationMap.jpg'
export const icon = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/smallLogo.png'
export const logo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/logo.png'
export const mobileApp = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/mobileApp.png'
export const pointsMeter =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/pointsMeter.png'
export const promoBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:eco/v2/sushihowe/promoBG.png'
export const promotion1 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v2/sushihowe/promotion1.png'
export const promotion2 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v2/sushihowe/promotion2.png'
export const promotion3 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v2/sushihowe/promotion3.png'
export const redeemButton =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/redeemButton.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/rewardArrowRight.png'
export const rewardBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/rewardbg.jpg'
export const smallLogo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/smallLogo.png'

export const woodbg = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/woodbg.jpg'
export const backgroundImage =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/woodbg.jpg'
export const pokeBowl = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/pokeBowl.png'
