import React, { useContext } from 'react'
import { FText, FView } from 'components'
import { css } from 'emotion'
import { useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'
import { MEDIA_QUERY } from 'styles'

const UserLoadedView = ({ UserDetailsElement, LogoutButtonElement }) => {
  const { Components, User, themeColors } = useContext(ThemeContext)
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  return (
    <FView fill w={1024} maxWidth={'100%'} selfCenter p={'1.5rem'}>
      <FView column row={isMinLG}>
        <Components.Card selfStart={isMinLG} selfCenter={!isMinLG} className={classes.userInfo}>
          <FText bold h5 h4={isMinMD} black style={{ margin: '.5rem' }}>
            Account Information
          </FText>
          <FView h={1} mb={'2.5rem'} bg={themeColors.grey} />
          {UserDetailsElement}
          <FView size={'1.5rem'} />
          <FView alignCenter>{LogoutButtonElement}</FView>
        </Components.Card>
        <FView size={'2.5rem'} />
        <Components.Card selfStart={isMinLG} selfCenter={!isMinLG} className={classes.orderHistory}>
          <User.OrderHistory.OrderHistory />
        </Components.Card>
      </FView>
    </FView>
  )
}

const classes = {
  userInfo: css({
    padding: '2.5rem 1.5rem',
    width: '50rem',
    maxWidth: '100%',
    [MEDIA_QUERY.MIN_SM]: {
      padding: '2.5rem 3rem',
    },
  }),
  orderHistory: css({
    padding: '2.5rem 1.5rem',
    width: '50rem',
    maxWidth: '100%',
  }),
}

export default UserLoadedView
