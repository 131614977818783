import React, { useContext } from 'react'
import { FLink } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function DownloadAppButton({ link, label = 'DOWNLOAD APP', heading, textProps }) {
  const { Components } = useContext(ThemeContext)
  return (
    <FLink href={link}>
      <Components.PaintedButtonView heading={heading} textProps={textProps} label={label} />
    </FLink>
  )
}
