import React from 'react'
import { App, ThemeContext } from 'f-web-app'
import * as fPages from './pages'
import * as fComponents from './components'
import * as fImages from './images'
import { COLOR as fCOLOR, TEXT as fTEXT } from './styles'

import AOS from 'aos'
import 'aos/dist/aos.css'

export default function createWebApp({ pages = {}, Components = {}, COLOR = {}, TEXT = {}, images = {}, aosSettings = {} }) {
  AOS.init({
    once: false,
    mirror: true,
    ...aosSettings,
  })

  // Merge fPages and pages up to 2 levels deep
  const mergedPages = Object.entries(fPages).reduce((prev, [fPageName, fPage]) => {
    const bothPages = { ...fPage, ...pages[fPageName] }
    prev[fPageName] = Object.keys(bothPages).reduce((prev, subPageName) => {
      const fSubPage = fPages[fPageName][subPageName]
      const subPage = pages[fPageName][subPageName]
      // Check if object is React Component
      if (typeof fSubPage === 'function' || typeof subPage === 'function') {
        prev[subPageName] = subPage || fSubPage
      } else {
        prev[subPageName] = { ...fSubPage, ...(subPage || {}) }
      }
      return prev
    }, {})
    return prev
  }, {})

  const mergedComponents = {
    ...fComponents,
    ...Components,
  }

  const mergedColors = {
    ...fCOLOR,
    ...COLOR,
  }

  const mergedImages = {
    ...fImages,
    ...images,
  }

  const mergedTexts = {
    ...fTEXT,
    ...TEXT,
  }

  const defaultThemeValue = {
    Components: mergedComponents,
    ...mergedPages,
    themeColors: mergedColors,
    themeImages: mergedImages,
    themeTexts: mergedTexts,
  }

  return function WebApp() {
    return (
      <ThemeContext.Provider value={defaultThemeValue}>
        <App />
      </ThemeContext.Provider>
    )
  }
}
