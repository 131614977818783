import React, { useState, useContext } from 'react'
import { css } from 'emotion'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MdClose } from 'react-icons/md'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { FButton, FView, FModal, FSelect, FText } from 'f-web-app/components'
import { dispatch } from 'f-web-app/store'
import * as images from 'f-web-app/images'
import { ThemeContext } from 'f-web-app'

const getDefaultState = (productId) => {
  const selectedModifiers = {}
  const modifierGroupIds = dispatch.restaurant.getProductModifierGroups(productId)
  if (modifierGroupIds) {
    modifierGroupIds.forEach((groupId) => {
      const groupDetails = dispatch.restaurant.getModifierGroupDetails(groupId)
      if (groupDetails && groupDetails.modifierItems) {
        Object.entries(groupDetails.modifierItems).forEach(([itemId, modifierItem]) => {
          if (!selectedModifiers[groupId]) {
            selectedModifiers[groupId] = {}
          }
          selectedModifiers[groupId][itemId] = modifierItem.defaultValue
        })
      }
    })
  }
  return selectedModifiers
}

export default function ProductItem({ name, description, price, modifierGroups, id }) {
  const { themeColors, Menu } = useContext(ThemeContext)
  const [selectedModifiers, setSelectedModifiers] = useState(getDefaultState(id))
  const [quantity, setQuantity] = useState(1)
  const [isAddProductModalVisible, setIsAddProductModalVisible] = useState(false)
  const isActive = useSelector(() => dispatch.restaurant.getIsProductActive(id))
  const hasModifierGroups = !isEmpty(modifierGroups)
  const closeModal = () => {
    setQuantity(1)
    setSelectedModifiers(getDefaultState(id))
    setIsAddProductModalVisible(false)
  }
  const selectModifierItem = (modifierGroupId, modifierItemId) => {
    const newModifiers = { ...selectedModifiers }
    if (!selectedModifiers[modifierGroupId]) {
      newModifiers[modifierGroupId] = { [modifierItemId]: true }
      setSelectedModifiers(newModifiers)
      return
    }
    const { isRequired, maxSelection } = dispatch.restaurant.getModifierGroupDetails(modifierGroupId)

    // Radio Button
    if (isRequired && maxSelection === 1) {
      if (!selectedModifiers[modifierGroupId][modifierItemId]) {
        newModifiers[modifierGroupId] = { [modifierItemId]: true }
        setSelectedModifiers(newModifiers)
      }
      return
    }

    // 1. Count how many existing items are selected
    const groupSelectionCount = Object.values(selectedModifiers[modifierGroupId]).reduce((prev, cur) => {
      return prev + (cur === true ? 1 : 0)
    }, 0)

    // 2. Is it a selection?
    if (!newModifiers[modifierGroupId][modifierItemId]) {
      newModifiers[modifierGroupId] = { ...selectedModifiers[modifierGroupId] }
      // 3. If it is a selection and there is no more room, uncheck one
      if (groupSelectionCount >= maxSelection) {
        for (const itemId of Object.keys(newModifiers[modifierGroupId])) {
          const isSelected = newModifiers[modifierGroupId][itemId]
          if (isSelected) {
            newModifiers[modifierGroupId][itemId] = false
            break
          }
        }
      }
      // 4. Simply select the option
      newModifiers[modifierGroupId][modifierItemId] = true
      setSelectedModifiers(newModifiers)
    } else {
      // 5. If the modifier is required, and selectionCount is 1, don't do anything
      if (isRequired && groupSelectionCount === 1) {
        return
      }
      // 6. Uncheck the item
      newModifiers[modifierGroupId] = { ...selectedModifiers[modifierGroupId] }
      newModifiers[modifierGroupId][modifierItemId] = false
      setSelectedModifiers(newModifiers)
    }
  }
  const handleAddToCart = () => {
    try {
      dispatch.user.addCartItem({ id, qty: quantity, selectedModifiers })
      closeModal()
      dispatch.notification.setMessage({
        message: `${name} added to Cart.`,
        level: 'success',
      })
    } catch (e) {
      dispatch.notification.setMessage({
        message: e.message,
        level: 'error',
      })
    }
  }

  return (
    <>
      <FButton disabled={!isActive} onClick={() => setIsAddProductModalVisible(true)}>
        <Menu.ProductItemView
          nameFormatted={name.toUpperCase()}
          isSoldOut={!isActive}
          priceFormatted={price.toFixed(2)}
          description={description}
        />
      </FButton>
      <FModal isOpen={isAddProductModalVisible} closeModal={closeModal}>
        <Menu.AddProductModalView
          name={name}
          quantity={quantity}
          modifierGroups={modifierGroups}
          selectedModifiers={selectedModifiers}
          CloseModalButtonElement={
            <FButton onClick={closeModal}>
              <MdClose color={themeColors.backgroundText} size={30} />
            </FButton>
          }
          DecreaseQtyButtonElement={
            <FButton disabled={quantity <= 1} onClick={() => setQuantity(quantity - 1)}>
              <FontAwesomeIcon
                icon="minus-circle"
                style={{ fontSize: '3rem' }}
                color={quantity <= 1 ? themeColors.lightGrey : themeColors.primary}
              />
            </FButton>
          }
          IncreaseQtyButtonElement={
            <FButton disabled={quantity >= 50} onClick={() => setQuantity(quantity + 1)}>
              <FontAwesomeIcon
                icon="plus-circle"
                style={{ fontSize: '3rem' }}
                color={quantity >= 50 ? themeColors.lightGrey : themeColors.primary}
              />
            </FButton>
          }
          ModifierGroupsElement={
            <>
              {!hasModifierGroups && (
                <FView fill center>
                  <img
                    src={images.addProductModalBG}
                    style={{ width: '50%', height: '50%', pointerEvents: 'none' }}
                    alt="bg"
                  />
                </FView>
              )}
              {hasModifierGroups && (
                <FView fill overflowY={'auto'}>
                  <FView block>
                    {modifierGroups.map((modifierGroupId) => {
                      return (
                        <FSelect
                          key={modifierGroupId}
                          selector={() => dispatch.restaurant.getModifierGroupDetails(modifierGroupId)}
                        >
                          {({ title, isRequired, maxSelection, modifierItems }) => (
                            <FView>
                              <FView h={70} justifyCenter pl={15} pr={30} bg={'#EDEDED'}>
                                <FText h7 bold grey>
                                  {title}
                                </FText>
                                <FView row justifyBetween>
                                  <FText h7 grey>
                                    {maxSelection > 10
                                      ? '(Choose As Many)'
                                      : maxSelection > 1
                                      ? `(Choose Up To ${maxSelection})`
                                      : '(Choose One)'}
                                  </FText>
                                  <FText h7 grey>
                                    {isRequired && '(Required)'}
                                  </FText>
                                </FView>
                              </FView>
                              {Object.keys(modifierItems)
                                .sort()
                                .map((modifierItemId, index) => {
                                  const { name, price, activeAfter } = modifierItems[modifierItemId]
                                  const selected = !!(
                                    selectedModifiers[modifierGroupId] &&
                                    selectedModifiers[modifierGroupId][modifierItemId]
                                  )
                                  const inStock = activeAfter == null || activeAfter.toDate() < new Date()
                                  return (
                                    <FButton
                                      key={modifierItemId}
                                      disabled={!inStock}
                                      onClick={() => selectModifierItem(modifierGroupId, modifierItemId)}
                                    >
                                      <FView
                                        h={50}
                                        bg="white"
                                        row
                                        bc="#D0D2D3"
                                        bw={0}
                                        btw={1}
                                        bbw={Object.keys(modifierItems).length - 1 === index ? 1 : 0}
                                        alignCenter
                                        justifyBetween
                                        ph={30}
                                      >
                                        <FView fill>
                                          <FText h7 bold grey primary={selected}>
                                            {name}
                                          </FText>
                                        </FView>
                                        <FView row alignCenter>
                                          <FText h8 bold style={{ marginRight: 10 }} grey primary={selected}>
                                            {inStock ? (
                                              price > 0 ? (
                                                `+$${price.toFixed(2)}`
                                              ) : price < 0 ? (
                                                `-$${(-price).toFixed(2)}`
                                              ) : (
                                                ''
                                              )
                                            ) : (
                                              <FText h8 bold primary>
                                                SOLD OUT
                                              </FText>
                                            )}
                                          </FText>
                                          <FView></FView>
                                          {inStock &&
                                            (selected ? (
                                              maxSelection === 1 && isRequired ? (
                                                <MdRadioButtonChecked size={30} color={themeColors.primary} />
                                              ) : (
                                                <MdCheckBox size={30} color={themeColors.primary} />
                                              )
                                            ) : maxSelection === 1 && isRequired ? (
                                              <MdRadioButtonUnchecked size={30} color={'#4A4A4A'} />
                                            ) : (
                                              <MdCheckBoxOutlineBlank size={30} color={'#4A4A4A'} />
                                            ))}
                                        </FView>
                                      </FView>
                                    </FButton>
                                  )
                                })}
                            </FView>
                          )}
                        </FSelect>
                      )
                    })}
                  </FView>
                </FView>
              )}
            </>
          }
          AddToCartButtonElement={
            <FButton onClick={handleAddToCart}>
              <FView h={60} bg={themeColors.primary} row alignCenter ph={30}>
                <FView fill />
                <FView row>
                  <FText h7 primaryText>
                    <FontAwesomeIcon className={classes.icon} icon={'shopping-cart'} />
                  </FText>
                  <FView w={10} />
                  <FText h7 primaryText>
                    Add to Cart
                  </FText>
                </FView>
                <FView fill alignEnd>
                  <FText h7 primaryText>
                    ${dispatch.user.getCartItemTotal({ count: quantity, selectedModifiers, price }).toFixed(2)}
                  </FText>
                </FView>
              </FView>
            </FButton>
          }
        />
      </FModal>
    </>
  )
}

const classes = {
  modal: css({
    backgroundColor: '#FFF',
    width: 600,
    maxWidth: '100vw',
    overflow: 'hidden',
    height: '65rem',
    maxHeight: 'calc(100vh - 100px)',
  }),
}
