import {db, getDocument} from 'f-core/src/config/firebase'
import momentTZ from 'moment-timezone'
import {get, isEqual, isEmpty} from 'lodash'
import * as utils from 'f-utils'

let unsubscribeRestaurantLocation = null

const DEFAULT_STATE = {
  // Restaurant data
  loading: true,
  restaurantId: null,
  Locations: {},
  name: null,
  restaurantPrivate: {},

  // Restaurant Location data
  restaurantLocationLoading: true,
  locationName: null,
  address: null,
  phoneNumber: null,
  email: null,
  Categories: {},
  Products: {},
  categoryOrder: [],
  activeCategoryOrder: [],
  activeProducts: {},
  hours: {}, // Deprecated
  menus: {},
  orderOpen: null, // Deprecated
  rewards: {},
  waitTime: 0,
  printingEnabled: false,
  isCashDeliveryEnabled: false,
  deliveryFee: 0,
  deliveryEnabled: false, // Deprecated
  deliveryMinimumSubTotal: 0,
  deliveryDistance: 0,
  deliveryTime: 0,
  restaurantLatLng: {lat: 0, lng: 0},
  waitTimeIncrementSubTotalStart: 0,
  waitTimeIncrementSubTotalInterval: 10,
  waitTimeIncrementAmount: 0,
  paymentProcessor: 'Stripe',
  timezone: 'America/Vancouver',
  availableTables: {},
  // Redux only data
  locationId: null,
}

export default (_restaurantId, _defaultLocationId) => ({
  state: {
    ...DEFAULT_STATE,
    restaurantId: _restaurantId,
    locationId: _defaultLocationId,
  },
  reducers: {
    resetRestaurant: () => DEFAULT_STATE,
    resetRestaurantLocation: ({restaurantId, Locations, loading, name}) => ({
      ...DEFAULT_STATE,
      restaurantId,
      Locations,
      loading,
      name,
    }),
    setRestaurantId: (state, restaurantId) => {
      return {...state, restaurantId}
    },
    setLocationId: (state, locationId) => {
      return {...state, locationId}
    },
    setRestaurant: (state, restaurant) => ({
      ...state,
      ...restaurant,
    }),
    setLocationPrivate: (state, locationPrivate) => ({
      ...state,
      locationPrivate,
    }),
    setRestaurantPrivate: (state, restaurantPrivate) => ({
      ...state,
      restaurantPrivate,
    }),
    setCategories: (state, Categories) => ({
      ...state,
      Categories,
    }),
    setProducts: (state, Products) => ({
      ...state,
      Products,
    }),
    setModifierGroups: (state, ModifierGroups) => ({
      ...state,
      ModifierGroups,
    }),
    setLocations: (state, Locations) => ({
      ...state,
      Locations,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setRestaurantLocationLoading: (state, restaurantLocationLoading) => ({
      ...state,
      restaurantLocationLoading,
    }),
    setActiveCategoryOrder: (state, activeCategoryOrder) => ({
      ...state,
      activeCategoryOrder,
    }),
    setActiveProducts: (state, activeProducts) => ({
      ...state,
      activeProducts,
    }),
  },
  actions: ({dispatch, getState}) => ({
    getLocationPrivate() {
      return getState().restaurant.locationPrivate
    },
    getRestaurantPrivate() {
      return getState().restaurant.restaurantPrivate
    },
    getRegion() {
      return getState().restaurant.region
    },
    getTimezone() {
      return getState().restaurant.timezone || 'America/Vancouver'
    },
    getAvailableTables() {
      return getState().restaurant.availableTables
    },
    getMoment(...params) {
      const timezone = dispatch.restaurant.getTimezone()
      return momentTZ(...params).tz(timezone)
    },
    getRestaurantId() {
      return getState().restaurant.restaurantId
    },
    getSelectedLocationId() {
      return getState().restaurant.locationId
    },
    getSelectedLocation() {
      const locations = dispatch.restaurant.getLocations()
      const selectedLocationId = dispatch.restaurant.getSelectedLocationId()
      if (locations && selectedLocationId && locations[selectedLocationId]) {
        return locations[selectedLocationId]
      }
    },
    getPaymentProcessor() {
      return getState().restaurant.paymentProcessor
    },
    getName() {
      return getState().restaurant.name
    },
    getLocationName() {
      return getState().restaurant.locationName
    },
    getAddress() {
      return getState().restaurant.address
    },
    getPhoneNumber() {
      return getState().restaurant.phoneNumber
    },
    getFormattedPhoneNumber() {
      return utils.formatPhoneNumber(dispatch.restaurant.getPhoneNumber())
    },
    getEmail() {
      return getState().restaurant.email
    },
    getHours() {
      return getState().restaurant.hours
    },
    getHoursLT() {
      return get(getState().restaurant, 'hoursLT', null)
    },
    getIsCashDeliveryEnabled() {
      return getState().restaurant.isCashDeliveryEnabled
    },
    getMenus() {
      return getState().restaurant.menus
    },
    getMenu(menuId) {
      return dispatch.restaurant.getMenus()[menuId] || {}
    },
    getMenuHours(menuId) {
      const {hours} = dispatch.restaurant.getMenu(menuId)
      const day = dispatch.restaurant.getMoment().day()
      return isEmpty(hours)
        ? 'Not Available'
        : hours[day] && hours[day].open && hours[day].close
        ? `${dispatch.restaurant.getMoment(hours[day].open).format('LT')} - ${dispatch.restaurant
            .getMoment(hours[day].close)
            .format('LT')}`
        : 'Not Available'
    },
    getIsMenuActive(menuId) {
      return dispatch.restaurant.getMenu(menuId).active
    },
    getActiveMenuCount() {
      return Object.values(dispatch.restaurant.getMenus()).reduce((prev, menu) => {
        if (menu.active) {
          prev++
        }
        return prev
      }, 0)
    },
    getIsPrintingEnabled() {
      return get(getState(), 'restaurant.printingEnabled', false)
    },
    getOrderOpen() {
      console.warn('getOrderOpen is deprecated! use getOrderOpenAfter or getOrderOpenDetails')
      return !!getState().restaurant.orderOpen
    },
    getOrderOpenAfter() {
      if (getState().restaurant.orderOpenAfter != null) {
        return getState().restaurant.orderOpenAfter
      }
      return dispatch.restaurant.getOrderOpen() ? 0 : 1e15
    },
    getIsStoreOpen({targetTime} = {}) {
      const myTargetTimeMoment = targetTime || dispatch.restaurant.getMoment()
      const timezone = dispatch.restaurant.getTimezone()
      const targetTimestamp = myTargetTimeMoment.valueOf()
      const orderOpenAfter = get(getState().restaurant, 'orderOpenAfter', null)
      if (orderOpenAfter !== null && targetTimestamp < orderOpenAfter) {
        return false
      }
      const hoursLT = dispatch.restaurant.getHoursLT()
      if (hoursLT) {
        // Greatly simplified starting version 2, only open if current time falls within one or more timeLTs in today's weekday
        return utils.getIsOrderOpenHoursLT({hoursLT, targetTimeMoment: myTargetTimeMoment, timezone})
      }

      // ==================== Deprecated =====================================
      // Store is open iff (not loading, orderOpen, now is during store hours)

      const orderOpen = getState().restaurant.orderOpen
      // return null if we don't know if order is open (ie. still loading)
      if (orderOpen === null) {
        return null
      }
      if (orderOpen === false) {
        return false
      }

      // To properly check if currentTime is between store hours,
      // Check both today's weekday and yesterday's weekday to see if currentTime is between open and close.

      const todayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getHours(),
        targetDate: dispatch.restaurant.getMoment(),
        time: dispatch.restaurant.getMoment(),
        waitTime: dispatch.restaurant.getWaitTime(),
      })
      if (todayOpen) {
        return true
      }

      const yesterdayOpen = utils.getIsOpenForGivenTime({
        hours: dispatch.restaurant.getHours(),
        targetDate: dispatch.restaurant.getMoment().subtract(1, 'days'),
        time: dispatch.restaurant.getMoment(),
        waitTime: dispatch.restaurant.getWaitTime(),
      })
      return yesterdayOpen
      // ======================================================================
    },
    getIsPickupAvailable() {
      return get(getState().restaurant, 'availableOrderTypes.Pickup', true)
    },
    getIsDeliveryAvailable() {
      return get(
        getState().restaurant,
        'availableOrderTypes.Delivery',
        get(getState().restaurant, 'deliveryEnabled', false),
      )
    },
    getIsDineInAvailable() {
      return get(getState().restaurant, 'availableOrderTypes.DineIn', false)
    },
    getRewards() {
      return getState().restaurant.rewards
    },
    // getVisibleRewards() {
    //   return Object.entries(dispatch.restaurant.getRewards()).reduce((prev, [rewardId, rewardDetails]) => {
    //     if (rewardDetails.visible !== false) {
    //       prev[rewardId] = rewardDetails
    //     }
    //     return prev
    //   }, {})
    // },
    getRewardsCount() {
      return Object.keys(dispatch.restaurant.getRewards()).length
    },
    getWaitTime() {
      return getState().restaurant.waitTime || 0
    },
    getCategoryOrder(menuId) {
      if (menuId) {
        return dispatch.restaurant.getMenu(menuId).categoryOrder || []
      }
      return getState().restaurant.categoryOrder
    },
    updateActiveCategoryOrder() {
      const menus = dispatch.restaurant.getMenus()
      const categoryOrder = Object.values(menus).reduce((prev, menu) => {
        const {active, hours} = menu
        if (active) {
          const isOpen = utils.getIsOpenForGivenTime({
            hours,
            targetDate: dispatch.restaurant.getMoment(),
            time: dispatch.restaurant.getMoment(),
            waitTime: 0,
          })

          if (isOpen) {
            const categoryOrder = get(menu, 'categoryOrder')
            prev = prev.concat(categoryOrder)
          } else {
            const isYesterdayOpen = utils.getIsOpenForGivenTime({
              hours,
              targetDate: dispatch.restaurant.getMoment().subtract(1, 'days'),
              time: dispatch.restaurant.getMoment(),
              waitTime: 0,
            })
            if (isYesterdayOpen) {
              const categoryOrder = get(menu, 'categoryOrder')
              prev = prev.concat(categoryOrder)
            }
          }
        }
        return prev
      }, [])
      const prevActiveCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()
      if (categoryOrder.length === 0 && menus.default) {
        if (!isEqual(prevActiveCategoryOrder, menus.default.categoryOrder)) {
          dispatch.restaurant.setActiveCategoryOrder(menus.default.categoryOrder)
        }
      } else if (!isEqual(prevActiveCategoryOrder, categoryOrder)) {
        dispatch.restaurant.setActiveCategoryOrder(categoryOrder)
      }
    },
    getActiveCategoryOrder() {
      return getState().restaurant.activeCategoryOrder || []
    },
    updateActiveProducts() {
      const activeProducts = {}
      const activeCategoryOrder = dispatch.restaurant.getActiveCategoryOrder()

      for (const categoryId of activeCategoryOrder) {
        const productOrder = dispatch.restaurant.getProductOrder(categoryId)
        for (const productId of productOrder) {
          // Check if product exists
          const productDetails = dispatch.restaurant.getProductDetails(productId)
          if (!productDetails) {
            continue
          }
          // Check if product is active
          if (!dispatch.restaurant.getIsProductActive(productId)) {
            continue
          }

          activeProducts[productId] = productDetails
        }
      }
      dispatch.restaurant.setActiveProducts(activeProducts)
    },
    getActiveProducts() {
      return getState().restaurant.activeProducts
    },
    getProductDetails(productId) {
      return getState().restaurant.Products[productId]
    },
    getModifierGroupDetails(modifierGroupId) {
      if (!modifierGroupId) {
        return null
      }
      const ModifierGroups = getState().restaurant.ModifierGroups
      return ModifierGroups ? ModifierGroups[modifierGroupId] : null
    },
    getProductModifierGroups(productId) {
      if (!productId) {
        return null
      }
      return get(dispatch.restaurant.getProductDetails(productId), 'modifierGroups', null)
    },
    getProductOptions(productId) {
      if (!productId) {
        return null
      }
      return get(dispatch.restaurant.getProductDetails(productId), 'options', null)
    },
    getProductOptionsDetails(productId) {
      // get array of optionIds
      const options = dispatch.restaurant.getProductOptions(productId)
      if (options) {
        return options.map((optionId) => dispatch.restaurant.getProductDetails(optionId))
      }
      return null
    },
    getProducts() {
      return getState().restaurant.Products
    },
    getModifierGroups() {
      return getState().restaurant.ModifierGroups
    },
    getCategories() {
      return getState().restaurant.Categories
    },
    getCategory(categoryId) {
      return dispatch.restaurant.getCategories()[categoryId]
    },
    getCategoryImageUrl(categoryId) {
      const category = dispatch.restaurant.getCategory(categoryId)
      return get(category, 'imageUrl')
    },
    getProductOrder(categoryId) {
      const category = dispatch.restaurant.getCategory(categoryId)
      return get(category, 'productOrder', [])
    },
    getOptions(productId) {
      return dispatch.restaurant.getProductDetails(productId).options || []
    },
    getLocations() {
      return getState().restaurant.Locations
    },
    getHasSingleLocation() {
      return Object.keys(dispatch.restaurant.getLocations()).length === 1
    },
    getHasMultipleLocations() {
      return Object.keys(dispatch.restaurant.getLocations()).length > 1
    },
    getLoading() {
      return getState().restaurant.loading || getState().restaurant.restaurantLocationLoading
    },
    getLoadingLocations() {
      return isEmpty(getState().restaurant.Locations)
    },
    getIsProductActive(productId) {
      const productDetails = dispatch.restaurant.getProductDetails(productId)
      return utils.getIsProductActive(productDetails)
    },
    getRestaurantDoc() {
      return db.collection('Restaurants').doc(dispatch.restaurant.getRestaurantId())
    },
    getSelectedLocationDoc() {
      return dispatch.restaurant.getLocationsDoc().doc(dispatch.restaurant.getSelectedLocationId())
    },
    getCategoriesDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Categories')
    },
    getProductsDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('Products')
    },
    getModifierGroupsDoc() {
      return dispatch.restaurant.getSelectedLocationDoc().collection('ModifierGroups')
    },
    getLocationsDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Locations')
    },
    getOrdersDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Orders')
    },
    getRestaurantPrivateDoc() {
      return dispatch.restaurant.getRestaurantDoc().collection('Private').doc('private')
    },
    getPointsInterval() {
      return getState().restaurant.pointsInterval
    },
    getPointsPerInterval() {
      return getState().restaurant.pointsPerInterval
    },
    getIsDeliveryEnabled() {
      console.warn('getIsDeliveryEnabled Deprecated')
      return dispatch.restaurant.getIsDeliveryAvailable()
    },
    getDeliveryMinimumSubTotal() {
      return get(getState().restaurant, 'deliveryMinimumSubTotal', 0)
    },
    getDeliveryFreeMinimumSubTotal() {
      return get(getState().restaurant, 'deliveryFreeMinimumSubTotal', 1000)
    },
    getDeliveryFee() {
      return get(getState().restaurant, 'deliveryFee', 0)
    },
    getDeliveryDistance() {
      return get(getState().restaurant, 'deliveryDistance', 0)
    },
    getDeliveryTime() {
      return get(getState().restaurant, 'deliveryTime', 15)
    },
    getDeliveryHoursLT() {
      return get(getState().restaurant, 'deliveryHoursLT', get(getState().restaurant, 'hoursLT', null))
    },
    getPaymentMethods() {
      return get(getState().restaurant, 'paymentMethods', {
        'online-creditcard': true,
        'inperson-cash': true,
      })
    },
    getIsOnlineCCEnabled() {
      const paymentMethods = dispatch.restaurant.getPaymentMethods()
      return get(paymentMethods, 'online-creditcard', true)
    },
    getIsInpersonCashEnabled() {
      const paymentMethods = dispatch.restaurant.getPaymentMethods()
      return get(paymentMethods, 'inperson-cash', true)
    },
    getIsInpersonCardEnabled() {
      const paymentMethods = dispatch.restaurant.getPaymentMethods()
      return get(paymentMethods, 'inperson-creditcard', false)
    },
    getOrderOpenDetails({targetTimeMoment = dispatch.restaurant.getMoment()} = {}) {
      const hoursLT = dispatch.restaurant.getHoursLT()
      if (!hoursLT) {
        return {
          isOpen: dispatch.restaurant.getIsStoreOpen({targetTime: targetTimeMoment}),
        }
      }
      const timezone = dispatch.restaurant.getTimezone()
      const orderOpenAfter = dispatch.restaurant.getOrderOpenAfter()
      const orderOpenAfterMoment = dispatch.restaurant.getMoment(orderOpenAfter)
      return utils.getOrderStatusDetails({hoursLT, targetTimeMoment, orderOpenAfterMoment, timezone})
    },
    getDeliveryOrderOpenDetails({targetTimeMoment = dispatch.restaurant.getMoment()} = {}) {
      const deliveryHoursLT = dispatch.restaurant.getDeliveryHoursLT()
      if (!deliveryHoursLT) {
        return dispatch.restaurant.getOrderOpenDetails({targetTimeMoment})
      }
      const timezone = dispatch.restaurant.getTimezone()
      const orderOpenAfter = dispatch.restaurant.getOrderOpenAfter()
      const orderOpenAfterMoment = dispatch.restaurant.getMoment(orderOpenAfter)
      return utils.getOrderStatusDetails({hoursLT: deliveryHoursLT, targetTimeMoment, orderOpenAfterMoment, timezone})
    },
    getOrderStatusDetails({hoursLT, targetTimeMoment = dispatch.restaurant.getMoment()}) {
      const timezone = dispatch.restaurant.getTimezone()
      const orderOpenAfter = dispatch.restaurant.getOrderOpenAfter()
      const orderOpenAfterMoment = dispatch.restaurant.getMoment(orderOpenAfter)
      return utils.getOrderStatusDetails({hoursLT, targetTimeMoment, orderOpenAfterMoment, timezone})
    },
    getRestaurantLatLng() {
      return get(getState().restaurant, 'restaurantLatLng', {lat: 0, lng: 0})
    },
    getWaitTimeIncrementSubTotalStart() {
      return getState().restaurant.waitTimeIncrementSubTotalStart
    },
    getWaitTimeIncrementSubTotalInterval() {
      return getState().restaurant.waitTimeIncrementSubTotalInterval
    },
    getWaitTimeIncrementAmount() {
      return getState().restaurant.waitTimeIncrementAmount
    },
    subscribeRestaurant() {
      const collectionLoadedStatus = {
        Restaurant: false,
        Locations: false,
        RestaurantPrivate: false,
      }
      let collectionsToLoadCount = Object.keys(collectionLoadedStatus).length
      const setCollectionToLoaded = (collectionName) => {
        if (collectionLoadedStatus[collectionName] === false) {
          collectionLoadedStatus[collectionName] = true
          collectionsToLoadCount--
          if (collectionsToLoadCount === 0) {
            dispatch.restaurant.setLoading(false)
          }
        }
      }
      const unsubsRestaurant = dispatch.restaurant.getRestaurantDoc().onSnapshot(
        (snapshot) => {
          const restaurantData = snapshot.data()
          setCollectionToLoaded('Restaurant')
          dispatch.restaurant.setRestaurant(restaurantData)
        },
        () => setCollectionToLoaded('Restaurant'),
      )
      const unsubsLocations = dispatch.restaurant.getLocationsDoc().onSnapshot(
        (snapshot) => {
          const locations = {}
          snapshot.forEach((doc) => {
            locations[doc.id] = doc.data()
            locations[doc.id].id = doc.id
          })

          // Set locationId if there is only one location
          const locationIds = Object.keys(locations)
          if (locationIds.length === 1) {
            dispatch.restaurant.setLocationId(locationIds[0])
          } else {
            const selectedLocationId = dispatch.restaurant.getSelectedLocationId()
            // Check if selectedLocationId is valid (that was set by user model)
            if (!locations[selectedLocationId]) {
              dispatch.restaurant.setLocationId(null)
            }
          }

          dispatch.restaurant.setLocations(locations)
          setCollectionToLoaded('Locations')
        },
        () => setCollectionToLoaded('Locations'),
      )
      const unsubRestaurantPrivate = dispatch.restaurant.getRestaurantPrivateDoc().onSnapshot(
        (snapshot) => {
          const restaurantPrivate = snapshot.data()
          dispatch.restaurant.setRestaurantPrivate(restaurantPrivate)
          setCollectionToLoaded('RestaurantPrivate')
        },
        () => setCollectionToLoaded('RestaurantPrivate'),
      )
      const unsubscribeAndResetRestaurant = () => {
        unsubsRestaurant()
        unsubsLocations()
        unsubRestaurantPrivate()
        dispatch.restaurant.resetRestaurant()
      }
      return unsubscribeAndResetRestaurant
    },
    subscribeRestaurantLocation() {
      unsubscribeRestaurantLocation && unsubscribeRestaurantLocation()
      dispatch.restaurant.setRestaurantLocationLoading(true)
      const collectionLoadedStatus = {
        LocationPrivate: false,
        Categories: false,
        Products: false,
        ModifierGroups: false,
        RestaurantLocation: false,
      }
      let collectionsToLoadCount = Object.keys(collectionLoadedStatus).length
      const setCollectionToLoaded = (collectionName) => {
        if (collectionLoadedStatus[collectionName] === false) {
          collectionLoadedStatus[collectionName] = true
          collectionsToLoadCount--
          if (collectionsToLoadCount === 0) {
            dispatch.restaurant.updateActiveCategoryOrder()
            dispatch.restaurant.updateActiveProducts()
            dispatch.restaurant.setRestaurantLocationLoading(false)
          }
        }
      }
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const unsubsLocationPrivate = getDocument('locationPrivate', {restaurantId, locationId}).onSnapshot(
        (snapshot) => {
          if (snapshot) {
            const locationPrivateData = snapshot.data()
            setCollectionToLoaded('LocationPrivate')
            dispatch.restaurant.setLocationPrivate(locationPrivateData)
          }
        },
        () => setCollectionToLoaded('LocationPrivate'),
      )
      const unsubsRestaurantLocation = dispatch.restaurant.getSelectedLocationDoc().onSnapshot(
        (snapshot) => {
          const selectedLocationData = snapshot.data()
          setCollectionToLoaded('RestaurantLocation')
          dispatch.restaurant.setRestaurant(selectedLocationData)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
        },
        () => setCollectionToLoaded('RestaurantLocation'),
      )
      const unsubsCategories = dispatch.restaurant.getCategoriesDoc().onSnapshot(
        (snapshot) => {
          const categories = {}
          snapshot.forEach((doc) => {
            categories[doc.id] = doc.data()
            categories[doc.id].id = doc.id
          })
          dispatch.restaurant.setCategories(categories)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
          setCollectionToLoaded('Categories')
        },
        () => setCollectionToLoaded('Categories'),
      )
      const unsubsProducts = dispatch.restaurant.getProductsDoc().onSnapshot(
        (snapshot) => {
          const products = {}
          snapshot.forEach((doc) => {
            products[doc.id] = doc.data()
            products[doc.id].id = doc.id
          })
          dispatch.restaurant.setProducts(products)
          dispatch.restaurant.updateActiveCategoryOrder()
          dispatch.restaurant.updateActiveProducts()
          setCollectionToLoaded('Products')
        },
        () => setCollectionToLoaded('Products'),
      )
      const unsubsModifierGroups = dispatch.restaurant.getModifierGroupsDoc().onSnapshot(
        (snapshot) => {
          const modifierGroups = {}
          snapshot.forEach((doc) => {
            modifierGroups[doc.id] = doc.data()
            modifierGroups[doc.id].id = doc.id
          })
          dispatch.restaurant.setModifierGroups(modifierGroups)
          setCollectionToLoaded('ModifierGroups')
        },
        () => setCollectionToLoaded('ModifierGroups'),
      )
      unsubscribeRestaurantLocation = () => {
        unsubsLocationPrivate()
        unsubsCategories()
        unsubsProducts()
        unsubsModifierGroups()
        unsubsRestaurantLocation()
      }
      return unsubscribeRestaurantLocation
    },
  }),
})
