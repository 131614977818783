import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function Page404View({ MenuButtonElement }) {
  const { themeColors, themeImages, Components } = useContext(ThemeContext)
  return (
    <FView
      minHeight={'100vh'}
      bg={themeColors.background}
      style={
        themeImages.backgroundImage && {
          background: `url(${themeImages.backgroundImage}) center center / cover no-repeat`,
        }
      }
    >
      <Components.PageHeader fixedHeight relative />
      <div className={classes.container}>
        <FText h4 error textCenter>
          Oops! This page doesn't exist!
        </FText>
        <FView size={'4rem'} />
        {MenuButtonElement}
      </div>
    </FView>
  )
}

const classes = {
  container: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '2rem',
    bottom: '12rem',
    padding: '2rem',
  }),
}
