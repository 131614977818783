import React from 'react'
import { css } from 'emotion'
import { foodGallery } from 'images'
import { FView, FText } from 'components'
import { useMediaMinLG } from 'f-web/src/hooks'

export default function About() {
  const isMinLG = useMediaMinLG()
  return (
    <FView className={classes.container} selfCenter mb="4rem">
      <FView mv={'3rem'}>
        <FText h4 h3={isMinLG} black bold textCenter heading>
          WE ALSO RECOMMEND
        </FText>
      </FView>
      <img className={classes.gallery} src={foodGallery} alt="Food Gallery" />
    </FView>
  )
}

const classes = {
  container: css({
    width: '100rem',
    maxWidth: '100%',
  }),
  gallery: css({
    width: '100%',
    objectFit: 'contain',
  }),
}
