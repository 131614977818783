import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

export default function ForgotPasswordModalView({ EmailInputElement, CancelButtonElement, SubmitButtonElement }) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView className={styles.container} rounded p="2rem" center bg={themeColors.background}>
      <FView w="100%">
        <FText h5 backgroundText>
          Enter your email to send password reset:
        </FText>
        <FView mv="2rem">{EmailInputElement}</FView>
        <FView row selfCenter>
          {CancelButtonElement}
          <FView size={'1rem'} />
          {SubmitButtonElement}
        </FView>
      </FView>
    </FView>
  )
}

const styles = {
  container: css({
    width: '48rem',
    maxWidth: '100vw',
  }),
}
