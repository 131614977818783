import React, { useContext } from 'react'
import { FView } from 'components'
import { ThemeContext } from 'f-web-app'

export default function HomeView({ locationId }) {
  const { Home, Components, themeColors } = useContext(ThemeContext)
  return (
    <FView bg={themeColors.background} overflowX={'hidden'}>
      <Components.PageHeader />
      <Home.Hero />
      <FView size="2rem" />
      <Home.Promotions />
      <FView size="2rem" />
      <Home.About />
      <FView size="2rem" />
      <Home.ContactUs
        appStoreUrl={'https://itunes.apple.com/ca/app/id1447963644'}
        googlePlayUrl="https://play.google.com/store/apps/details?id=tech.codefusion.sushihowe"
      />
      <FView size="2rem" />
      <Home.Footer />
    </FView>
  )
}
