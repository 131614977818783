import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

const OrderButtonView = ({ disabled, label }) => {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView center className={styles.button} bg={themeColors.primary} style={disabled ? { opacity: 0.5 } : {}}>
      <FText h5 primaryText bold>
        {label}
      </FText>
    </FView>
  )
}

const styles = {
  button: css({
    paddingLeft: '3rem',
    paddingRight: '3rem',
    height: '3.5rem',
    border: 0,
    borderRadius: '0.4rem',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
}

export default OrderButtonView
