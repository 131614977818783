import React from 'react'
import { FText, FView } from 'components'

export default function UserDetailsTextView({ text }) {
  return (
    <FView pl={'1.5rem'}>
      <FText h7 grey>
        {text}
      </FText>
    </FView>
  )
}
