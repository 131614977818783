import React, { Fragment, useState, useEffect, useContext } from 'react'
import { FButton, FSelect, FModal } from 'f-web-app/components'
import { useSelector } from 'react-redux'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'

export default function LocationSelect() {
  const [showModal, setShowModal] = useState(false)
  const selectedLocationId = useSelector(dispatch.restaurant.getSelectedLocationId)
  const locations = useSelector(dispatch.restaurant.getLocations)
  const { Components } = useContext(ThemeContext)
  useEffect(() => {
    if (!selectedLocationId) {
      setShowModal(true)
    }
  }, [selectedLocationId])

  return (
    <FSelect selector={dispatch.restaurant.getHasMultipleLocations}>
      <FSelect selector={dispatch.restaurant.getSelectedLocationId}>
        <FButton onClick={() => setShowModal(true)}>
          <Components.LocationSelectNameView selectedLocationName={<FSelect selector={dispatch.restaurant.getLocationName} />} />
        </FButton>
      </FSelect>
      <FModal isOpen={showModal}>
        <Components.LocationSelectModalView
          LocationSelectListElement={Object.values(locations).map(({ id, locationName, address }) => (
            <Fragment key={id}>
              <FButton
                onClick={() => {
                  dispatch.restaurant.setLocationId(id)
                  setShowModal(false)
                }}
              >
                <Components.LocationSelectButtonView id={id} name={locationName} address={address} />
              </FButton>
            </Fragment>
          ))}
        />
      </FModal>
    </FSelect>
  )
}
