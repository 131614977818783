import React, { useState, useContext, useEffect } from 'react'
import { dispatch } from 'f-web-app/store'
import { FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function StoreClosedBanner() {
  const { themeColors } = useContext(ThemeContext)
  const [isStoreOpen, setIsStoreOpen] = useState(true)
  const [storeOpenMoment, setStoreOpenMoment] = useState(null)
  useEffect(() => {
    const internvalID = setInterval(() => {
      const orderOpenDetails = dispatch.restaurant.getOrderOpenDetails()
      if (orderOpenDetails.isOpen !== isStoreOpen) {
        setIsStoreOpen(orderOpenDetails.isOpen)
      }
      if (orderOpenDetails.openMoment !== storeOpenMoment) {
        setStoreOpenMoment(orderOpenDetails.openMoment)
      }
    }, 1000)
    return () => clearInterval(internvalID)
  }, [isStoreOpen, storeOpenMoment])
  return isStoreOpen ? null : (
    <FView p={'1rem'} mb="1rem" mh={7} w="100%" bg={themeColors.warning} rounded>
      <FText h6 white bold textCenter>
        ORDERING IS CLOSED
      </FText>
      {!!storeOpenMoment && (
        <>
          <FText h7 white bold textCenter>
            *Opens {storeOpenMoment.calendar()}
          </FText>
        </>
      )}
    </FView>
  )
}
