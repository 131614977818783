import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { useMediaMinXS, useMediaMinSM, useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function CategoryItemView({ index, imageUrl, isSelected, name }) {
  const { themeColors, themeImages } = useContext(ThemeContext)
  const isMinXS = useMediaMinXS()
  const isMinSM = useMediaMinSM()
  const isMinLG = useMediaMinLG()
  return (
    <FView className={classes.container}>
      <FView relative className={classes.imageContainer}>
        {imageUrl ? (
          <img className={classes.image} src={imageUrl} alt="Category" />
        ) : (
          <img className={classes.iconImage} src={themeImages.icon} alt="Category" />
        )}
        {isMinLG && (
          <FView
            className={classes.animate}
            absolute
            h="37vw"
            w={'100%'}
            maxHeight="13.5rem"
            bg={themeColors.black25}
            opacity={isSelected ? 0 : 1}
          />
        )}
      </FView>
      <FText
        h9
        h8={isMinXS}
        h6={isMinSM}
        heading
        bold
        color={isMinLG && isSelected ? themeColors.primary : themeColors.black}
        textCenter
      >
        {name ? name.toUpperCase() : ''}
      </FText>
    </FView>
  )
}

const classes = {
  container: css({
    cursor: 'pointer',
    height: '50vw',
    width: '50vw',
    maxHeight: '19rem',
    maxWidth: '20.5rem',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  imageContainer: css({
    height: '37vw',
    width: '100%',
    maxHeight: '13.5rem',
  }),
  image: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }),
  iconImage: css({
    width: '100%',
    height: '100%',
    padding: '1rem',
    objectFit: 'contain',
  }),
  animate: css({
    transition: 'opacity .5s cubic-bezier(.19,1,.4,1)',
  }),
}
