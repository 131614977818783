import createRestaurantModel from './restaurantModel'

export default (restaurantId, defaultLocationId) => ({
  config: {
    models: {
      restaurant: createRestaurantModel(restaurantId, defaultLocationId),
    },
  },
  onStoreCreated(store) {
    if (restaurantId) {
      store.dispatch({type: 'restaurant/subscribeRestaurant'})
    }
  },
})
