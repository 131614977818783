import React, { useEffect, useContext, useRef, useState } from 'react'
import { ThemeContext } from 'f-web-app'
import { dispatch } from 'f-web-app/store'
import { moment } from 'f-utils'

export default function OrderComplete({ orderId }) {
  const { Cart, Components } = useContext(ThemeContext)
  const [order, setOrder] = useState({})
  const unsubscribeOrderRef = useRef()
  useEffect(() => {
    if (unsubscribeOrderRef.current) {
      unsubscribeOrderRef.current()
    }
    unsubscribeOrderRef.current =
      orderId &&
      dispatch.user.getOrderDoc({ orderId }).onSnapshot((snapshot) => {
        setOrder(snapshot.data())
      })
    return unsubscribeOrderRef.current
  }, [setOrder, orderId])
  return (
    <Cart.OrderCompleteView
      {...order}
      OrderStatusElement={<Components.OrderStatusView {...order} />}
      // Deprecated on July 21, 2019. Use completionTime instead.
      completionTimeFormatted={moment(order.estimatedCompletionTime).format('h:mm A')}
    />
  )
}
