import React from 'react'
import { FText } from 'components'
import { css } from 'emotion'

const AddedChargeRowView = ({ name, priceFormatted }) => (
  <div className={classes.productItem}>
    <FText h7 grey className={classes.name} bold>
      {name}
    </FText>
    <FText bold h7 grey>
      ${priceFormatted}
    </FText>
  </div>
)

const classes = {
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
  name: css({
    flex: 1,
  }),
}

export default AddedChargeRowView
