import React, { useContext } from 'react'
import { FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function MenuView() {
  const { Menu, Components, themeImages, themeColors } = useContext(ThemeContext)
  return (
    <FView
      minHeight={'100vh'}
      bg={themeColors.background}
      overflow="hidden"
      style={
        themeImages.backgroundImage && {
          background: `url(${themeImages.backgroundImage}) center center / cover no-repeat`,
        }
      }
    >
      <Components.PageHeader relative fixedHeight />
      <Menu.MenuList />
    </FView>
  )
}
