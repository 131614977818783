import React from 'react'
import { FView, FText } from 'components'
import { css } from 'emotion'
import { COLOR, MEDIA_QUERY } from 'styles'

const OrderHistoryRowView = ({ orderNumber, createdAtFormatted, status, totalFormatted }) => {
  return (
    <FView className={styles.orderItem} rounded row>
      <FView column alignStart>
        <FText h7 black bold>{`#${orderNumber}`}</FText>
        <FText h7 black>
          {createdAtFormatted}
        </FText>
      </FView>
      <FView column alignEnd>
        <FText h7 black bold>
          {status}
        </FText>
        <FText h7 black>
          ${totalFormatted}
        </FText>
      </FView>
    </FView>
  )
}

const styles = {
  orderItem: css({
    justifyContent: 'space-between',
    padding: '2rem',
    margin: '.5rem 0 .5rem 0',
    cursor: 'pointer',
    border: '1px solid ' + COLOR.grey50,
  }),
  date: css({
    width: '65%',
    display: 'flex',
    justifyContent: 'space-between',
    [MEDIA_QUERY.SM]: {
      width: 'auto',
    },
  }),
}

export default OrderHistoryRowView
