import { db, firebaseAuth, DELETE_FIELD_VALUE } from 'f-core/src/config/firebase'
import { TYPES } from 'f-core/src/constants'
import * as utils from 'f-utils'
import * as api from '../../api'
import { get, omit, isEmpty, orderBy, keys } from 'lodash'
import paymentActions from './paymentActions'

const DEFAULT_STATE = {
  // User Restaurant data
  cart: {},
  notes: '', // local only
  orderType: null, // local only
  tipSelectionIndex: 1, // local only
  tableNumber: null, // local only
  recentOrders: {},
  reviewOrderId: '',
  points: 0,
  promos: {},
  deliveryAddress: '',
  deliveryUnit: '',
  deliveryInstructions: '',

  // User data
  id: '',
  payment: {},
  phoneNumber: '',
  email: '',
  name: '',
  loading: false,
  loadingUserRestaurant: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isPlacingOrder: false,
  notificationToken: '',

  // User Last Order
  lastUserOrder: {},
  loadingLastUserOrder: null,
}

export default (stripeKey, serverUrl) => ({
  state: DEFAULT_STATE,
  reducers: {
    _setUser: (state, user) => ({
      ...state,
      ...user,
    }),
    _unsetUser: () => DEFAULT_STATE,
    _setLastUserOrder: (state, lastUserOrder) => ({
      ...state,
      lastUserOrder,
    }),
    setIsPlacingOrder: (state, isPlacingOrder) => ({
      ...state,
      isPlacingOrder,
    }),
    setIsLoggingIn: (state, isLoggingIn) => ({
      ...state,
      isLoggingIn,
    }),
    setIsLoggingOut: (state, isLoggingOut) => ({
      ...state,
      isLoggingOut,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setLoadingUserRestaurant: (state, loadingUserRestaurant) => ({
      ...state,
      loadingUserRestaurant,
    }),
    setLoadingLastUserOrder: (state, loadingLastUserOrder) => ({
      ...state,
      loadingLastUserOrder,
    }),
    _setCart: (state, cart) => ({
      ...state,
      cart,
    }),
    _addCartItem: (state, { id, qty, selectedModifiers }) => ({
      ...state,
      cart: {
        ...state.cart,
        [utils.moment().valueOf()]: {
          productId: id,
          selectedModifiers,
          count: qty,
        },
      },
    }),
    _subtractCartItem: (state, { cartItemId }) => ({
      ...state,
      cart:
        state.cart[cartItemId].count === 1
          ? omit(state.cart, cartItemId)
          : {
              ...state.cart,
              [cartItemId]: {
                ...state.cart[cartItemId],
                count: state.cart[cartItemId].count - 1,
              },
            },
    }),
    _clearCart: (state) => ({
      ...state,
      cart: DEFAULT_STATE.cart,
    }),
    _addRecentOrderProducts(state, products) {
      const productIdsObj = Object.keys(products).reduce((prev, next) => {
        prev[next] = true
        return prev
      }, {})
      return {
        ...state,
        recentOrders: [...productIdsObj, ...state.recentOrders],
      }
    },
    _setDeliveryAddress(state, { deliveryAddress, deliveryUnit, deliveryInstructions }) {
      return { ...state, deliveryAddress, deliveryUnit, deliveryInstructions }
    },
    setNotes(state, notes) {
      return {
        ...state,
        notes,
      }
    },
    setOrderType(state, orderType) {
      return {
        ...state,
        orderType,
      }
    },
    setTipSelectionIndex(state, tipSelectionIndex) {
      return {
        ...state,
        tipSelectionIndex,
      }
    },
    _clearNotes(state) {
      return {
        ...state,
        notes: DEFAULT_STATE.notes,
      }
    },
    clearRecentOrders(state) {
      return {
        ...state,
        recentOrders: DEFAULT_STATE.recentOrders,
      }
    },
    _clearPromos(state) {
      return {
        ...state,
        promos: DEFAULT_STATE.promos,
      }
    },
  },
  actions: ({ dispatch, getState }) => ({
    ...paymentActions({ dispatch, getState, stripeKey, serverUrl }),
    setTableNumber(tableNumber) {
      return dispatch.user._setUser({ tableNumber })
    },
    getTableNumber() {
      return getState().user.tableNumber
    },
    getUserToken() {
      return api.auth.getUserToken()
    },
    getUserId() {
      return get(api.auth.getCurrentUser(), 'uid')
    },
    getCart() {
      const cart = getState().user.cart
      const activeProducts = dispatch.restaurant.getActiveProducts()
      const products = dispatch.restaurant.getProducts()
      return Object.entries(cart).reduce((prev, [cartItemId, cartItem]) => {
        if (activeProducts[cartItem.productId]) {
          // Check if cartItem meets all required modifier group requirements
          const productDetails = products[cartItem.productId]
          if (productDetails.modifierGroups && productDetails.modifierGroups.length > 0) {
            const requiredGroupIds = productDetails.modifierGroups.reduce((prev, groupId) => {
              const groupDetails = dispatch.restaurant.getModifierGroupDetails(groupId)
              if (groupDetails && groupDetails.isRequired) {
                prev.push(groupId)
              }
              return prev
            }, [])
            const selectedGroupIds = Object.entries(cartItem.selectedModifiers).reduce(
              (prev, [groupId, modifierItemObj]) => {
                for (const isSelected of Object.values(modifierItemObj)) {
                  if (isSelected) {
                    prev.push(groupId)
                    break
                  }
                }
                return prev
              },
              []
            )
            const containsAll = requiredGroupIds.every((groupId) => selectedGroupIds.includes(groupId))

            if (containsAll) {
              prev[cartItemId] = cartItem
            }
          } else {
            prev[cartItemId] = cartItem
          }
        }
        return prev
      }, {})
    },
    getIsCartEmpty() {
      return dispatch.user.getCartCount() === 0
    },
    async getIsEmailExists(email) {
      if (!email) {
        throw new Error('Email cannot be empty')
      }
      return await api.auth.isEmailExists(email)
    },
    getIsLoggingIn() {
      return getState().user.isLoggingIn
    },
    getIsLoggingOut() {
      return getState().user.isLoggingOut
    },
    getIsLoading() {
      return getState().user.loading || getState().user.loadingUserRestaurant || !!getState().user.loadingLastUserOrder
    },
    getIsPlacingOrder() {
      return getState().user.isPlacingOrder
    },
    getIsLoggedIn() {
      return !!api.auth.getCurrentUser()
    },
    getEmail() {
      return api.auth.getCurrentUser().email
    },
    getPhoneNumber() {
      return getState().user.phoneNumber
    },
    getName() {
      return getState().user.name
    },
    getIsFacebookLinked() {
      for (const provider of api.auth.getCurrentUser().providerData) {
        if (provider.providerId === 'facebook.com') {
          return true
        }
      }
      return false
    },
    getPhotoURL() {
      return getState().user.photoURL
    },
    getRecentOrders() {
      return getState().user.recentOrders
    },
    getRecentOrdersSorted() {
      const activeProducts = dispatch.restaurant.getActiveProducts()

      const recentOrders = dispatch.user.getRecentOrders()
      const validRecentOrders = Object.keys(recentOrders).reduce((prev, productId) => {
        if (activeProducts[productId]) {
          prev[productId] = recentOrders[productId]
        }
        return prev
      }, {})

      return orderBy(keys(validRecentOrders), (k) => validRecentOrders[k], 'desc')
    },
    getCartWithProductDetails() {
      const restaurantProducts = dispatch.restaurant.getProducts()
      const cartItems = dispatch.user.getCart()
      return Object.entries(cartItems).reduce((prev, [cartItemId, cartItem]) => {
        prev[cartItemId] = {
          ...cartItems[cartItemId],
          ...restaurantProducts[cartItem.productId],
        }
        return prev
      }, {})
    },
    getCartCount() {
      let cartCount = 0
      const cartItemsArr = Object.values(dispatch.user.getCart())
      for (const cartItem of cartItemsArr) {
        cartCount += cartItem.count
      }

      const promosArr = Object.values(dispatch.user.getValidPromosWithDetails())
      for (const promo of promosArr) {
        cartCount += promo.count
      }

      return cartCount
    },
    getCartItemTotal({ selectedModifiers, price, count }) {
      if (!selectedModifiers) {
        return Math.max(price * count, 0)
      }
      const modifierTotalPrice = Object.entries(selectedModifiers).reduce(
        (prev, [modifierGroupId, modifierItemObj]) => {
          const modifierGroupDetails = dispatch.restaurant.getModifierGroupDetails(modifierGroupId)
          if (!modifierGroupDetails || !modifierItemObj) {
            return prev
          }
          return (
            prev +
            Object.entries(modifierItemObj).reduce((prev, [modifierItemId, isSelected]) => {
              if (!modifierGroupDetails.modifierItems[modifierItemId]) {
                return prev
              }
              return prev + (isSelected ? modifierGroupDetails.modifierItems[modifierItemId].price : 0)
            }, 0)
          )
        },
        0
      )

      return Math.max((price + modifierTotalPrice) * count, 0)
    },
    getCartSubTotalBeforeDiscount() {
      const cartProductWithDetails = dispatch.user.getCartWithProductDetails()
      if (!cartProductWithDetails) {
        return 0
      }
      return utils.currencyRounding(
        Object.values(cartProductWithDetails).reduce((prev, cartItemWithProductDetails) => {
          const { count, selectedModifiers, price } = cartItemWithProductDetails
          const cartItemPrice = dispatch.user.getCartItemTotal({ selectedModifiers, count, price })
          return prev + cartItemPrice
        }, 0)
      )
    },
    getCartSubTotal() {
      return utils.currencyRounding(dispatch.user.getCartSubTotalBeforeDiscount() - dispatch.user.getCartDiscount())
    },
    getCartWaitTime() {
      const waitTime = dispatch.restaurant.getWaitTime()
      const waitTimeIncrementSubTotalStart = dispatch.restaurant.getWaitTimeIncrementSubTotalStart()
      const waitTimeIncrementSubTotalInterval = dispatch.restaurant.getWaitTimeIncrementSubTotalInterval()
      const waitTimeIncrementAmount = dispatch.restaurant.getWaitTimeIncrementAmount()

      const cartSubTotalBeforeDiscount = dispatch.user.getCartSubTotalBeforeDiscount()
      if (cartSubTotalBeforeDiscount > waitTimeIncrementSubTotalStart) {
        const additionalWaitTime =
          Math.floor(
            (cartSubTotalBeforeDiscount - waitTimeIncrementSubTotalStart) / waitTimeIncrementSubTotalInterval
          ) * waitTimeIncrementAmount
        return waitTime + additionalWaitTime
      } else {
        return waitTime
      }
    },
    getCartDiscount() {
      const promos = dispatch.user.getValidPromosWithDetails()
      const promoIds = Object.keys(promos)

      let totalDiscount = 0
      for (const promoId of promoIds) {
        const promoDetails = promos[promoId]
        if (promoDetails.totalDiscountSubtotal > 0) {
          totalDiscount += promoDetails.totalDiscountSubtotal
        }
      }

      // Cannot get discount more than cartSubTotal
      return utils.currencyRounding(Math.min(totalDiscount, dispatch.user.getCartSubTotalBeforeDiscount()))
    },
    getCartTax() {
      const orderType = dispatch.user.getOrderType()
      if (orderType === 'Delivery') {
        return utils.currencyRounding((dispatch.user.getCartSubTotal() + dispatch.user.getDeliveryFee()) * 0.05)
      }

      return utils.currencyRounding(dispatch.user.getCartSubTotal() * 0.05)
    },
    getDeliveryFee() {
      const orderType = dispatch.user.getOrderType()
      if (orderType === 'Delivery') {
        const minSubTotal = dispatch.restaurant.getDeliveryFreeMinimumSubTotal()
        if (minSubTotal == null || dispatch.user.getCartSubTotalBeforeDiscount() < minSubTotal) {
          return dispatch.restaurant.getDeliveryFee()
        }
      }
      return 0
    },
    getCartTotal() {
      const orderType = dispatch.user.getOrderType()
      if (orderType === 'Delivery') {
        return utils.currencyRounding(
          dispatch.user.getCartSubTotal() +
            dispatch.user.getDeliveryFee() +
            dispatch.user.getCartTax() +
            dispatch.user.getTipAmount()
        )
      }

      return utils.currencyRounding(
        dispatch.user.getCartSubTotal() + dispatch.user.getCartTax() + dispatch.user.getTipAmount()
      )
    },
    getReviewOrderId() {
      return getState().user.reviewOrderId || ''
    },
    getPoints() {
      return getState().user.points || 0
    },
    getPointsMax() {
      const points = dispatch.user.getPoints()
      if (points <= 1000) {
        return 1000
      }
      return (Math.floor((points - 1) / 1000) + 1) * 1000
    },
    getPointsWithPromoApplied() {
      const promos = dispatch.user.getValidPromosWithDetails()
      const promoIds = Object.keys(promos)
      let pointsRedeemedTotal = 0
      for (const promoId of promoIds) {
        const promoDetails = promos[promoId]
        if (promoDetails.totalRequiredPoints > 0) {
          pointsRedeemedTotal += promoDetails.totalRequiredPoints
        }
      }

      return dispatch.user.getPoints() - pointsRedeemedTotal
    },
    getOrderHistoryRange({ fromDate, toDate, limit = 100 }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const userId = dispatch.user.getUserId()
      return api.orders.getOrderHistoryRange({
        restaurantId,
        userId,
        fromDate,
        toDate,
        limit,
      })
    },
    getNotes() {
      return getState().user.notes
    },
    getOrderType() {
      const deliveryAvailable = dispatch.restaurant.getIsDeliveryAvailable()
      return !deliveryAvailable ? 'Pickup' : getState().user.orderType
    },
    async changeUserInfo({ name, phoneNumber }) {
      const userInfo = {}
      const nameTrimmed = name && name.trim()
      const numerOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)

      if (nameTrimmed) {
        userInfo.name = nameTrimmed
        if (nameTrimmed.length > 18) {
          throw new Error('Name is too long')
        }
      }
      if (numerOnlyPhoneNumber) {
        if (!numerOnlyPhoneNumber || numerOnlyPhoneNumber.length !== 10) {
          throw new Error('Incorrect Phone Number. Please enter format of 7783334444')
        }
        userInfo.phoneNumber = numerOnlyPhoneNumber
      }
      if (dispatch.user.getIsLoggedIn() && !isEmpty(userInfo)) {
        return api.user.updateUser(dispatch.user.getUserId(), userInfo)
      }
    },
    signInWithEmailAndPassword({ email, password, onSuccess }) {
      return new Promise((resolve, reject) => {
        if (!email) {
          reject(new Error('Email cannot be empty'))
          return
        }
        if (!password) {
          reject(new Error('Password cannot be empty'))
          return
        }
        dispatch.user.setIsLoggingIn(true)
        try {
          api.auth
            .signInWithEmailAndPassword(email, password)
            .then((res) => {
              const uid = res.uid || get(res, 'user.uid')
              dispatch({ type: TYPES.ANALYTICS.LOGIN_SUCCESS, id: uid, email })
              onSuccess && onSuccess(res)
              dispatch.user.setIsLoggingIn(false)
              resolve(`Logged in as ${email}`)
            })
            .catch((error) => {
              dispatch({ type: TYPES.ANALYTICS.LOGIN_FAIL, error, email })
              dispatch.user.setIsLoggingIn(false)
              reject(error)
            })
        } catch (e) {
          dispatch({ type: TYPES.ANALYTICS.ERROR, error: e, email })
          dispatch.user.setIsLoggingIn(false)
          reject(e)
        }
      })
    },
    createUserWithEmailAndPassword({ email, password, phoneNumber, name = '', onSuccess }) {
      return new Promise((resolve, reject) => {
        if (!email || email.length === 0) {
          reject(new Error('Email cannot be empty'))
          return
        }
        if (!password || password.length === 0) {
          reject(new Error('Password cannot be empty'))
          return
        }
        const numerOnlyPhoneNumber = utils.removeNonNumericString(phoneNumber)
        if (numerOnlyPhoneNumber.length > 0 && numerOnlyPhoneNumber.length !== 10) {
          reject(new Error('Incorrect Phone Number. Please enter format of 7783334444'))
          return
        }
        dispatch.user.setIsLoggingIn(true)
        api.auth
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            const uid = res.uid || get(res, 'user.uid')
            const userData = {
              id: uid,
              email: email.toLowerCase(),
              name: name.trim(),
              role: 'customer',
              phoneNumber: numerOnlyPhoneNumber,
            }
            dispatch({ type: TYPES.ANALYTICS.REGISTER_SUCCESS, ...userData })
            return api.user.createUser(uid, userData)
          })
          .then(() => {
            onSuccess && onSuccess()
            resolve(`Registered as ${email}`)
          })
          .catch((err) => {
            dispatch({ type: TYPES.ANALYTICS.REGISTER_FAIL, error: err, email })
            dispatch.user._unsetUser()
            reject(err)
          })
          .finally(() => {
            dispatch.user.setIsLoggingIn(false)
          })
      })
    },
    clearCart() {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: DEFAULT_STATE.cart,
          promos: DEFAULT_STATE.promos,
        })
      } else {
        dispatch.user._clearCart()
        dispatch.user._clearPromos()
      }
      dispatch.user._clearNotes()
    },
    setCart(cartToSet) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: cartToSet,
        })
      } else {
        dispatch.user._setCart(cartToSet)
      }
    },
    addCartItem({ id, qty, selectedModifiers }) {
      const isAuthed = dispatch.user.getIsLoggedIn()

      const modifierGroups = dispatch.restaurant.getModifierGroups()

      // Check if modifier is required but not selected
      for (const [groupId, modifierItems] of Object.entries(selectedModifiers)) {
        if (modifierGroups[groupId].isRequired) {
          const hasSelection = Object.values(modifierItems).reduce((prev, selected) => selected || prev, false)
          if (!hasSelection) {
            throw new Error('You must select all required options')
          }
        }
      }

      if (isAuthed) {
        const cart = dispatch.user.getCart()
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        api.user.updateUserRestaurant(userId, restaurantId, {
          cart: {
            ...cart,
            [utils.moment().valueOf()]: {
              productId: id,
              selectedModifiers,
              count: qty,
            },
          },
        })
      } else {
        dispatch.user._addCartItem({ id, qty, selectedModifiers })
      }
    },
    subtractCartItem({ cartItemId }) {
      const isAuthed = dispatch.user.getIsLoggedIn()
      if (isAuthed) {
        const userId = dispatch.user.getUserId()
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const cart = dispatch.user.getCart()

        api.user.updateUserRestaurant(userId, restaurantId, {
          cart:
            cart[cartItemId].count === 1
              ? omit(cart, cartItemId)
              : {
                  ...cart,
                  [cartItemId]: {
                    ...cart[cartItemId],
                    count: cart[cartItemId].count - 1,
                  },
                },
        })
      } else {
        dispatch.user._subtractCartItem({ cartItemId })
      }
    },
    getPromos() {
      const defaultRewards = dispatch.user.getDefaultRewards()
      const defaultRewardsObj = Object.keys(defaultRewards).reduce((prev, rewardId) => {
        prev[rewardId] = {
          id: rewardId,
          count: 1,
        }
        return prev
      }, {})
      const promos = getState().user.promos
      return { ...promos, ...defaultRewardsObj }
    },
    getValidPromosWithDetails() {
      const promos = dispatch.user.getPromos()
      const promoIds = Object.keys(promos)
      const rewards = dispatch.restaurant.getRewards()
      const productsDetails = dispatch.restaurant.getProducts()
      const cartSubTotal = dispatch.user.getCartSubTotalBeforeDiscount()
      const isLoggedIn = dispatch.user.getIsLoggedIn()
      const isFirstOrder = dispatch.user.getIsFirstOrder()
      const points = dispatch.user.getPoints()
      const orderType = dispatch.user.getOrderType()

      const validPromoWithDetails = {}
      let remainingPoints = points

      for (const promoId of promoIds) {
        const rewardInfo = rewards[promoId]
        if (!rewardInfo) {
          continue
        }

        for (let remainingCount = promos[promoId].count; remainingCount > 0; remainingCount--) {
          const rewardDetails = utils.parseRewardInfo({
            rewardInfo,
            isFirstOrder,
            cartSubTotal,
            products: productsDetails,
            count: remainingCount,
          })

          const { valid } = utils.getIsRewardValid({
            isLoggedIn,
            rewardDetails,
            isFirstOrder,
            productsDetails,
            cartSubTotal,
            redeemedRewardCount: remainingCount,
            userPointsWithPromoApplied: remainingPoints - (rewardDetails.totalRequiredPoints || 0),
            orderType,
          })

          if (valid) {
            validPromoWithDetails[promoId] = { ...rewardDetails, id: promoId, count: remainingCount }
            break
          }
        }
      }

      return validPromoWithDetails
    },
    getRewardDetails({ rewardId }) {
      const promos = dispatch.user.getPromos()
      let count = 0
      if (promos[rewardId]) {
        count = promos[rewardId].count || 0
      }
      const rewards = dispatch.restaurant.getRewards()
      const productsDetails = dispatch.restaurant.getProducts()
      const cartSubTotal = dispatch.user.getCartSubTotalBeforeDiscount()
      const isLoggedIn = dispatch.user.getIsLoggedIn()
      const userPointsWithPromoApplied = dispatch.user.getPointsWithPromoApplied()
      const isFirstOrder = dispatch.user.getIsFirstOrder()

      const rewardDetails = utils.parseRewardInfo({
        rewardInfo: rewards[rewardId],
        isFirstOrder,
        productsDetails,
        cartSubTotal,
        count,
      })
      const rewardRedeemable = utils.getIsRewardRedeemable({
        isLoggedIn,
        rewardDetails,
        isFirstOrder,
        productsDetails,
        cartSubTotal,
        redeemedRewardCount: count,
        userPointsWithPromoApplied,
      })

      return { ...rewardDetails, ...rewardRedeemable }
    },
    getRewardsWithDetails() {
      const rewards = dispatch.restaurant.getRewards()
      const rewardsWithDetails = {}
      const rewardIds = Object.keys(rewards)
      for (const rewardId of rewardIds) {
        const rewardDetails = dispatch.user.getRewardDetails({ rewardId })
        rewardsWithDetails[rewardId] = rewardDetails
        rewardsWithDetails[rewardId].id = rewardId
      }
      return rewardsWithDetails
    },
    getDefaultRewards() {
      const rewards = dispatch.restaurant.getRewards()
      return Object.entries(rewards).reduce((prev, [rewardId, rewardDetails]) => {
        if (rewardDetails.appliedDefault === true) {
          prev[rewardId] = rewardDetails
        } else if (rewardDetails.type.startsWith('FreeFirstOrderOrPoints')) {
          const isFirstOrder = dispatch.user.getIsFirstOrder()
          if (isFirstOrder) {
            prev[rewardId] = rewardDetails
          }
        }
        return prev
      }, {})
    },
    getRewardsCount() {
      return Object.keys(dispatch.user.getRewardsWithDetails()).length
    },
    getRewardsWithDetailsSorted() {
      const rewardsWithDetails = dispatch.user.getRewardsWithDetails()
      return orderBy(Object.values(rewardsWithDetails), (v) => v.requiredPoints)
    },
    getTipSelectionIndex() {
      return getState().user.tipSelectionIndex
    },
    getTipAmount() {
      const tipSelectionIndex = dispatch.user.getTipSelectionIndex()
      if (tipSelectionIndex === null) {
        return 0
      }

      const total = dispatch.user.getCartSubTotal() + dispatch.user.getDeliveryFee() + dispatch.user.getCartTax()

      switch (tipSelectionIndex) {
        case 0:
          if (total < 5) {
            return 0.5
          } else {
            return utils.currencyRounding(total * 0.1)
          }
        case 1:
          if (total < 5) {
            return 1
          } else {
            return utils.currencyRounding(total * 0.15)
          }
        case 2:
          if (total < 5) {
            return 1.5
          } else {
            return utils.currencyRounding(total * 0.2)
          }
        default:
          return 0
      }
    },
    setDeliveryAddress({ deliveryAddress = '', deliveryUnit = '', deliveryInstructions = '' }) {
      if (dispatch.user.getIsLoggedIn()) {
        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          deliveryAddress,
          deliveryUnit,
          deliveryInstructions,
        })
      } else {
        dispatch.user._setDeliveryAddress({ deliveryAddress, deliveryUnit, deliveryInstructions })
        return Promise.resolve(true)
      }
    },
    getDeliveryAddress() {
      return getState().user.deliveryAddress
    },
    getDeliveryUnit() {
      return getState().user.deliveryUnit
    },
    getDeliveryInstructions() {
      return getState().user.deliveryInstructions
    },
    getCartRewardPoints() {
      const subTotal = dispatch.user.getCartSubTotal()
      const pointsInterval = dispatch.restaurant.getPointsInterval()
      const pointsPerInterval = dispatch.restaurant.getPointsPerInterval()
      return utils.calculatePointsEarned(subTotal, pointsInterval, pointsPerInterval)
    },
    addPromo(code) {
      if (dispatch.user.getIsLoggedIn()) {
        const promos = dispatch.user.getPromos()
        const count = get(promos[code], 'count', 0)

        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          ['promos.' + code]: {
            id: code,
            count: count + 1,
          },
        })
      } else {
        // We don't allow adding promo while not logged in
        throw new Error('Please log in to redeem rewards!')
      }
    },
    removePromo(code) {
      if (dispatch.user.getIsLoggedIn()) {
        const promos = dispatch.user.getValidPromosWithDetails()
        const count = get(promos[code], 'count', 0)
        if (count <= 1) {
          return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
            ['promos.' + code]: DELETE_FIELD_VALUE,
          })
        } else {
          return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
            ['promos.' + code]: {
              id: code,
              count: count - 1,
            },
          })
        }
      }
    },
    clearPromos() {
      if (dispatch.user.getIsLoggedIn()) {
        return api.user.updateUserRestaurant(dispatch.user.getUserId(), dispatch.restaurant.getRestaurantId(), {
          promos: {},
        })
      }
    },
    async signOut() {
      try {
        dispatch.user.setIsLoggingOut(true)
        const res = await api.auth.signOut()
        dispatch.user._unsetUser()
        return res
      } finally {
        dispatch.user.setIsLoggingOut(false)
      }
    },
    getOrderDoc({ orderId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return db.collection('Restaurants').doc(restaurantId).collection('Orders').doc(orderId)
    },
    getOrder({ orderId }) {
      return dispatch.user
        .getOrderDoc({ orderId })
        .get()
        .then((doc) => {
          if (doc.exists) {
            const orderData = doc.data()
            orderData.id = doc.id
            return orderData
          }
          return null
        })
    },
    setNotificationToken({ notificationToken }) {
      const userId = dispatch.user.getUserId()
      if (userId) {
        return api.user.updateUser(userId, { notificationToken })
      }
      return Promise.reject(new Error('Must be logged in'))
    },
    updateOrderReview({ orderId, review }) {
      const userId = dispatch.user.getUserId()
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return new Promise((resolve, reject) => {
        api.orders
          .updateOrder({ restaurantId, orderId, order: { review } })
          .then(() => {
            api.user
              .updateUserRestaurant(userId, restaurantId, {
                reviewOrderId: null,
              })
              .then(resolve)
              .catch(reject)
          })
          .catch(reject)
      })
    },
    getLastUserOrder() {
      return getState().user.lastUserOrder
    },
    getIsLastUserOrderLoading() {
      return getState().user.loadingLastUserOrder
    },
    getIsFirstOrder() {
      const loading = dispatch.user.getIsLastUserOrderLoading()
      if (loading === false) {
        const lastUserOrder = dispatch.user.getLastUserOrder()
        return !get(lastUserOrder, 'id')
      } else {
        return false
      }
    },
    getUserDoc() {
      const userId = dispatch.user.getUserId()
      return db.collection('Users').doc(userId)
    },
    getUserRestaurantDoc() {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return dispatch.user.getUserDoc().collection('Restaurants').doc(restaurantId)
    },
    getLastUserOrderDoc() {
      const userId = dispatch.user.getUserId()
      return dispatch.restaurant.getOrdersDoc().where('userId', '==', userId).orderBy('createdAt', 'desc').limit(1)
    },
    subscribeAuth() {
      dispatch.user.setLoading(true)
      let unsubscribeUser = null
      let unsubscribeUserRestaurant = null
      let unsubscribeLastUserOrder = null
      firebaseAuth.onAuthStateChanged((user) => {
        unsubscribeUser && unsubscribeUser()
        unsubscribeUserRestaurant && unsubscribeUserRestaurant()
        unsubscribeLastUserOrder && unsubscribeLastUserOrder()
        if (isEmpty(user)) {
          dispatch.user._unsetUser()
          dispatch.user.setLoading(false)
        } else {
          // Update createdAt and lastLogin
          dispatch.user
            .getUserDoc()
            .get()
            .then((doc) => {
              const restaurantId = dispatch.restaurant.getRestaurantId()
              const currentTimestamp = utils.moment().valueOf()

              const userData = doc.data() || {}

              // CREATE or UPDATE USER's id, email, and role
              if (!userData.role || !userData.email || userData.email !== user.email) {
                api.user.createUser(user.uid, {
                  id: user.uid,
                  email: user.email,
                  role: 'customer',
                })
              }

              // CREATE OR UPDATE USER's name
              if (!userData.name && user.displayName) {
                api.user.createUser(user.uid, {
                  name: user.displayName,
                })
              }

              // CREATE OR UPDATE USER's facebookUid and/or googleUid
              if (user.providerData) {
                for (const provider of user.providerData) {
                  if (provider.providerId === 'facebook.com' && userData.facebookUid !== provider.uid) {
                    api.user.createUser(user.uid, {
                      facebookUid: provider.uid,
                    })
                  } else if (provider.providerId === 'google.com' && userData.googleUid !== provider.uid) {
                    api.user.createUser(user.uid, {
                      googleUid: provider.uid,
                    })
                  }
                }
              }

              // CREATE OR UPDATE USER's phoneNumber
              if (!userData.phoneNumber && user.phoneNumber) {
                const numerOnlyPhoneNumber = utils.removeNonNumericString(user.phoneNumber)
                if (numerOnlyPhoneNumber && numerOnlyPhoneNumber.length === 10) {
                  api.user.createUser(user.uid, {
                    phoneNumber: numerOnlyPhoneNumber,
                  })
                }
              }

              // CREATE OR UPDATE USER's photoURL
              if (user.providerData && user.providerData[0] && user.providerData[0].photoURL) {
                if (user.providerData[0].providerId === 'facebook.com') {
                  api.user.createUser(user.uid, {
                    photoURL: user.providerData[0].photoURL + '?width=200',
                  })
                } else {
                  api.user.createUser(user.uid, {
                    photoURL: user.providerData[0].photoURL,
                  })
                }
              }

              // CREATE or UPDATE USER's createdAt, and/or lastLogin
              if (!userData.createdAt) {
                // User doesn't have createdAt
                api.user.createUser(user.uid, {
                  createdAt: currentTimestamp,
                  lastLogin: currentTimestamp,
                })
              } else {
                api.user.createUser(user.uid, {
                  lastLogin: currentTimestamp,
                })
              }

              // CREATE or UPDATE USER RESTAURANT's createdAt, and/or lastLogin
              if (
                !userData.restaurants ||
                !userData.restaurants[restaurantId] ||
                !userData.restaurants[restaurantId].createdAt
              ) {
                api.user.createUser(user.uid, {
                  restaurants: {
                    [restaurantId]: {
                      createdAt: currentTimestamp,
                      lastLogin: currentTimestamp,
                    },
                  },
                })
              } else {
                api.user.createUser(user.uid, {
                  restaurants: {
                    [restaurantId]: {
                      lastLogin: currentTimestamp,
                    },
                  },
                })
              }
            })
          unsubscribeUser = dispatch.user.getUserDoc().onSnapshot(
            (snapshot) => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const userData = snapshot.data() || {}
                userData.id = snapshot.id
                dispatch.user._setUser(userData)
              }
              dispatch.user.setLoading(false)
            },
            (error) => {
              console.warn(error)
              dispatch.user.setLoading(false)
            }
          )
          dispatch.user.setLoadingUserRestaurant(true)
          unsubscribeUserRestaurant = dispatch.user.getUserRestaurantDoc().onSnapshot(
            (snapshot) => {
              if (!dispatch.user.getIsLoggedIn()) {
                dispatch.user._unsetUser()
              } else {
                const userRestaurantData = snapshot.data()
                dispatch.user._setUser(userRestaurantData || {})
              }
              dispatch.user.setLoadingUserRestaurant(false)
            },
            (error) => {
              console.warn(error)
              dispatch.user.setLoadingUserRestaurant(false)
            }
          )
          dispatch.user.setLoadingLastUserOrder(true)
          unsubscribeLastUserOrder = dispatch.user.getLastUserOrderDoc().onSnapshot(
            (snapshots) => {
              snapshots.forEach((snapshot) => {
                if (!dispatch.user.getIsLoggedIn()) {
                  dispatch.user._unsetUser()
                } else {
                  const lastOrderData = snapshot.data()
                  lastOrderData.id = snapshot.id
                  dispatch.user._setLastUserOrder(lastOrderData)
                }
              })
              dispatch.user.setLoadingLastUserOrder(false)
            },
            (error) => console.warn(error)
          )
        }
      })
    },
  }),
})
