import React, { useContext } from 'react'
import { FView } from 'f-web-app/components'
import { css } from 'emotion'
import { MEDIA_QUERY, COLOR } from 'styles'
import { ThemeContext } from 'f-web-app'

const CartView = ({ loading, CartSummaryOrOrderCompleteElement }) => {
  const { themeColors, Cart, Components } = useContext(ThemeContext)
  return (
    <FView minHeight="100vh" overflowY={'scroll'} className={classes.container}>
      <Components.PageHeader relative fixedHeight />
      {loading ? (
        <FView minHeight="80vh" center>
          <Components.Spinner color={themeColors.backgroundText} />
        </FView>
      ) : (
        <FView className={classes.content} center>
          <div className={classes.border}>
            <Cart.Rewards.Rewards rewardImageClass={classes.rewardImage} />
            <FView size="4rem" />
            {CartSummaryOrOrderCompleteElement}
          </div>
        </FView>
      )}
    </FView>
  )
}

const classes = {
  container: css({
    background:
      'url(https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/sushihowe/woodbg.jpg) center center / cover no-repeat',
  }),
  content: css({
    width: '100%',
  }),
  rewardImage: css({
    height: 120,
    width: 177,
    objectFit: 'cover',
    borderRadius: 13,
  }),
  border: css({
    display: 'flex',
    alignItems: 'center',
    width: 1024,
    maxWidth: '100%',
    flexDirection: 'column-reverse',
    margin: '2rem 0 2rem 0',
    padding: '2rem 0rem 2rem 0rem',
    [MEDIA_QUERY.MIN_LG]: {
      backgroundColor: COLOR.black50,
      paddingRight: '4rem',
      paddingLeft: '4rem',
      flexDirection: 'row',
    },
  }),
}

export default CartView
