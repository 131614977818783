import React, { useContext } from 'react'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'
import useReactRouter from 'use-react-router'
import { useSelector } from 'react-redux'
import { FButton, FView } from 'f-web-app/components'

export default function User() {
  const { history } = useReactRouter()
  const loading = useSelector(dispatch.user.getIsLoading)
  const { themeColors, User, Components } = useContext(ThemeContext)
  return (
    <User.UserView>
      {loading ? (
        <FView h={'50rem'} center>
          <Components.Spinner color={themeColors.backgroundText} />
        </FView>
      ) : (
        <User.UserLoadedView
          UserDetailsElement={<User.UserDetails.UserDetails history={history} />}
          LogoutButtonElement={
            <FButton
              onClick={() => {
                history.push('/')
                dispatch.user.signOut()
                dispatch.notification.setMessage({
                  message: 'Successfully logged out!',
                  level: 'success',
                })
              }}
            >
              <Components.PaintedButtonView label="LOGOUT" />
            </FButton>
          }
        />
      )}
    </User.UserView>
  )
}
