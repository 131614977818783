import React from 'react'
import { FText, FView } from 'f-web-app/components'
import { css } from 'emotion'

const LocationSelectNameView = ({ selectedLocationName }) => (
  <FView className={classes.location}>
    <FText h3 backgroundText>
      {selectedLocationName}
    </FText>
  </FView>
)

const classes = {
  location: css({
    cursor: 'pointer',
    marginLeft: '1rem',
    marginRight: '1rem',
  }),
}

export default LocationSelectNameView
