import * as OrderHistory from './OrderHistory'
import * as UserDetails from './UserDetails'

export { OrderHistory, UserDetails }
export { default as User } from './User'

// Presentation
export { default as AddedChargeRowView } from './AddedChargeRowView'
export { default as OrderDetailsView } from './OrderDetailsView'
export { default as ProductRowView } from './ProductRowView'
export { default as RewardRowView } from './RewardRowView'
export { default as UserDetailsView } from './UserDetailsView'
export { default as UserLoadedView } from './UserLoadedView'
export { default as OrderHistoryRowView } from './OrderHistoryRowView'
export { default as OrderHistoryEmptyView } from './OrderHistoryEmptyView'
export { default as UserDetailsPaymentView } from './UserDetailsPaymentView'
export { default as UserDetailsTextView } from './UserDetailsTextView'
export { default as OrderHistoryView } from './OrderHistoryView'
export { default as UserView } from './UserView'

export const phoneNumberInputProps = {
  placeholder: 'Enter Phone Number',
}
export const nameInputProps = {
  placeholder: 'Enter Name',
}
