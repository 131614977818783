import React, { useContext } from 'react'
import { FButton } from 'f-web-app/components'
import useReactRouter from 'use-react-router'
import { ThemeContext } from 'f-web-app'

export default function Page404() {
  const { Page404 } = useContext(ThemeContext)
  const { history } = useReactRouter()
  return (
    <Page404.Page404View
      MenuButtonElement={
        <FButton onClick={() => history.push('/menu')}>
          <Page404.MenuButtonView />
        </FButton>
      }
    />
  )
}
