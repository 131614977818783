import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR } from 'f-web-app/styles'

const EditButtonView = () => <FontAwesomeIcon icon="edit" className={styles.button} />

const styles = {
  button: css({
    color: COLOR.primary,
    cursor: 'pointer',
    fontSize: '2.1rem',
  }),
}

export default EditButtonView
