import React, { useContext } from 'react'
import { css } from 'emotion'
import { FLink, FView, Space, FText } from 'components'
import { appStore, googlePlay, locationMap } from 'images'
import { MEDIA_QUERY } from 'styles'
import { ThemeContext } from 'f-web-app'

export default function Contact() {
  const { Components } = useContext(ThemeContext)
  return (
    <div className={styles.container}>
      <FLink href="https://goo.gl/maps/66drtG9vnbJ2">
        <img src={locationMap} alt="Location" className={styles.map} />
      </FLink>
      <Space h2 />
      <div className={styles.column}>
        <FText din h2 red bold>
          CONTACT US
        </FText>
        <Space h2 />
        <Components.ContactUs>{props => <Components.ContactUsView {...props} />}</Components.ContactUs>
        <Space height="2rem" />
        <FText din h2 red bold>
          DOWNLOAD APP
        </FText>
        <Space h2 />
        <FView row>
          <div className={styles.buttonBar}>
            <FLink href="https://itunes.apple.com/ca/app/id1447963644">
              <img className={styles.mobile} src={appStore} alt="App Store" />
            </FLink>
            <Space w2 />
            <FLink href="https://play.google.com/store/apps/details?id=tech.codefusion.sushihowe">
              <img className={styles.mobile} src={googlePlay} alt="Goodle Play" />
            </FLink>
          </div>
        </FView>
      </div>
      <Space h2 />
      <div className={styles.column}>
        <FText din h2 red bold>
          BUSINESS HOURS
        </FText>
        <Space h2 />
        <div className={styles.hours}>
          <Components.Hours>{props => <Components.HoursView {...props} />}</Components.Hours>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '120rem',
    padding: '4rem',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  map: css({
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
    },
  }),
  buttonBar: css({
    display: 'flex',
  }),
}
