import React, { useContext } from 'react'
import { FText, FView } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RewardRowView = ({ count, name, totalDiscountSubtotal }) => {
  const { themeColors } = useContext(ThemeContext)
  return (
    <div className={classes.productItem}>
      <FView center size={'2.2rem'} bg={themeColors.grey}>
        <FText h8 textCenter white className={classes.count}>
          x{count}
        </FText>
      </FView>
      <FView mh="1rem">
        <FontAwesomeIcon icon={'award'} style={{ fontSize: '2rem' }} color={themeColors.grey} />
      </FView>
      <FText h7 grey className={classes.name} bold>
        {name}
      </FText>
      <FText bold h7 grey>
        {totalDiscountSubtotal ? `-$${totalDiscountSubtotal.toFixed(2)}` : 'FREE'}
      </FText>
    </div>
  )
}

const classes = {
  productItem: css({
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  }),
  count: css({
    minWidth: '3rem',
  }),
  name: css({
    flex: 1,
  }),
}

export default RewardRowView
