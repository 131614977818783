import React, { useContext } from 'react'
import { css } from 'emotion'
import { NavLink } from 'react-router-dom'
import { FText } from 'f-web-app/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeContext } from 'f-web-app'

export default function IconLink({ className, children, label, icon, ...props }) {
  const { themeColors } = useContext(ThemeContext)
  const classNames = className ? css(classes.baseStyle, className) : classes.baseStyle
  return (
    <NavLink
      className={classNames}
      style={{ color: themeColors.black }}
      activeStyle={{ color: themeColors.primary }}
      {...props}
    >
      <FontAwesomeIcon className={classes.icon} icon={icon} />
      <FText bold h6>
        {label}
      </FText>
    </NavLink>
  )
}

const classes = {
  baseStyle: css({
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  icon: css({
    marginRight: '1rem',
    fontSize: '2.5rem',
  }),
}
