import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, Space, FText } from 'f-web-app/components'
import { MEDIA_QUERY } from 'f-web-app/styles'
import { ThemeContext } from 'f-web-app'
import { useMediaMinMD } from 'f-web/src/hooks'

const AuthView = ({
  GoBackButtonElement,
  EmailInputElement,
  PasswordInputElement,
  NameInputElement,
  PhoneInputElement,
  NextButtonElement,
  TermsAndConditionsElement,
  LoginButtonElement,
  ForgotPasswordButtonElement,
}) => {
  const { themeColors, themeImages, Components } = useContext(ThemeContext)
  const isMinMD = useMediaMinMD()
  return (
    <FView
      minHeight={'100vh'}
      bg={themeColors.background}
      style={
        themeImages.backgroundImage && {
          background: `url(${themeImages.backgroundImage}) center center / cover no-repeat`,
        }
      }
    >
      <Components.PageHeader fixedHeight relative />
      <div className={classes.content}>
        <Components.Card className={classes.border}>
          <FText bold h5 h4={isMinMD} black style={{ margin: '.5rem' }}>
            Login
          </FText>
          <FView h={1} mb={'2.5rem'} bg={themeColors.grey} />
          <div className={classes.form}>
            <div>{GoBackButtonElement}</div>
            <Space h2 />
            <FText bold h7 black>
              Email:
            </FText>
            <FView size={'0.5rem'} />
            {EmailInputElement}
            <Space h1 />
            {PasswordInputElement && (
              <>
                <FText bold h7 black>
                  Password:
                </FText>
                <FView size={'0.5rem'} />
                {PasswordInputElement}
              </>
            )}
            <Space h1 />
            {NameInputElement && (
              <>
                <FText bold h7 black>
                  Name:
                </FText>
                <FView size={'0.5rem'} />
                <FView mb="1rem">{NameInputElement}</FView>
              </>
            )}
            {PhoneInputElement && (
              <>
                <FText bold h7 black>
                  Phone Number:
                </FText>
                <FView size={'0.5rem'} />
                <FView mb="1rem">{PhoneInputElement}</FView>
              </>
            )}
            {NextButtonElement}
            <Space h1 />
            {LoginButtonElement}
            <FView size="2rem" />
            {TermsAndConditionsElement}
            {ForgotPasswordButtonElement}
          </div>
        </Components.Card>
      </div>
    </FView>
  )
}

const classes = {
  content: css({
    flex: 1,
    alignSelf: 'center',
    width: '50rem',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  border: css({
    width: '50rem',
    flexDirection: 'column',
  }),
  form: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    [MEDIA_QUERY.SM]: {
      marginTop: '2rem',
    },
  }),
  logoContainer: css({
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '25rem',
    padding: '2rem',
    marginRight: '4rem',
  }),
  logo: css({
    height: '20rem',
    maxWidth: '30rem',
    objectFit: 'contain',
    [MEDIA_QUERY.SM]: {
      width: '70%',
    },
  }),
}

export default AuthView
