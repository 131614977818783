import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function PaintedButtonView({
  className,
  style,
  heading,
  label,
  black,
  grey,
  secondary,
  disabled,
  disabledStyle,
  textProps,
  ...props
}) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView
      center
      className={css(styles.button, disabled && styles.disabled, className, style, disabled && disabledStyle)}
      bg={black ? themeColors.black : grey ? themeColors.grey : secondary ? themeColors.secondary : themeColors.primary}
      {...props}
    >
      <FText heading={heading} h7 bold primaryText white={black || grey} {...textProps}>
        {label}
      </FText>
    </FView>
  )
}

const styles = {
  button: css({
    width: '25rem',
    height: '5rem',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  disabled: css({
    opacity: 0.5,
  }),
}
