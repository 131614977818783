import React from 'react'
import { FText, FView } from 'components'

export default function EmptyCategoryView() {
  return (
    <FView fill mt={'5rem'}>
      <FText h4 backgroundText textCenter>
        Category is Empty
      </FText>
    </FView>
  )
}
