import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

const REWARD_VIEW_WIDTH = 375

export default function RewardItemView({ imageUrl }) {
  const { themeImages } = useContext(ThemeContext)
  return (
    <FView center className={classes.container}>
      {imageUrl ? (
        <img className={classes.image} src={imageUrl} alt="Reward" />
      ) : (
        <img className={classes.rewardDefault} src={themeImages.icon} alt="Reward" />
      )}
      {!!themeImages.rewardBorder && (
        <img className={classes.rewardBorder} src={themeImages.rewardBorder} alt="Reward Border" />
      )}
    </FView>
  )
}

const classes = {
  container: css({
    height: 550,
    width: REWARD_VIEW_WIDTH,
  }),
  image: css({
    position: 'relative',
    height: 200,
    width: 200,
    borderRadius: 100,
    objectFit: 'cover',
  }),
  rewardDefault: css({
    position: 'relative',
    objectFit: 'contain',
    width: 120,
  }),
  rewardBorder: css({
    width: 225,
    heigth: 225,
    position: 'absolute',
  }),
}
