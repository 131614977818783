import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { MEDIA_QUERY } from 'f-web-app/styles'
import { ThemeContext } from 'f-web-app'

const LocationSelectModalView = ({ LocationSelectListElement }) => {
  const { themeImages } = useContext(ThemeContext)
  return (
    <div className={classes.container}>
      <FView column w="100%">
        <FView column w="100%" center mb="1rem">
          <img src={themeImages.logo} alt="Foodly" width="300" />
        </FView>
        <FText h2 center backgroundText>
          Please pick from the following locations:
        </FText>
        {LocationSelectListElement}
      </FView>
    </div>
  )
}

const classes = {
  container: css({
    backgroundColor: '#232323',
    color: 'white',
    padding: '4rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45rem',
    [MEDIA_QUERY.SM]: {
      padding: '2rem',
      width: '100%',
    },
  }),
}

export default LocationSelectModalView
