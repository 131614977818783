import { init } from '@rematch/core'
import { plugins } from 'f-core'
import { plugin as restaurantPlugin } from 'f-models-restaurant'
import { plugin as userPlugin } from 'f-models-user'
import * as models from 'f-web-app/models'
import ENV from 'f-web-app/ENV'

export const store = init({
  models,
  plugins: [
    plugins.actions,
    restaurantPlugin(ENV.RESTAURANT_ID, ENV.LOCATION_ID),
    userPlugin(ENV.STRIPE_KEY, ENV.SERVER_URL),
  ],
})

export const { dispatch, getState } = store
