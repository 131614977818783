import React, { useContext } from 'react'
import { FButton } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function CancelButton({ disabled, onClick }) {
  const { Components } = useContext(ThemeContext)
  return (
    <FButton disabled={disabled} onClick={onClick}>
      <Components.CancelButtonView disabled={disabled} />
    </FButton>
  )
}
