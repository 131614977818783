import React from 'react'
import { FView } from 'f-web-app/components'
import { useMediaMinLG } from 'f-web/src/hooks'

export default function Gallery({ images }) {
  const isMinLG = useMediaMinLG()
  return (
    <FView m={15}>
      {isMinLG ? (
        <FView w={1000} selfCenter>
          <FView row>
            <FView>
              <img src={images[0]} alt="gallery" style={{ width: 358, height: 239 }} />
              <img src={images[1]} alt="gallery" style={{ width: 358, height: 239 }} />
            </FView>
            <img src={images[2]} alt="gallery" style={{ width: 654, height: 491 }} />
          </FView>
          <FView row wrap>
            {images.map((url, i) => {
              if (i < 3) return null
              return (
                <img key={i} src={url} alt="gallery" style={{ objectFit: 'contain', height: 238, width: 'auto' }} />
              )
            })}
          </FView>
        </FView>
      ) : (
        <FView row overflowX={'auto'}>
          {images.map((url, i) => (
            <img key={i} src={url} alt="gallery" style={{ objectFit: 'contain', height: 200, width: 'auto' }} />
          ))}
        </FView>
      )}
    </FView>
  )
}
