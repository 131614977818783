import React, { useContext } from 'react'
import { dispatch } from 'f-web-app/store'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'f-web-app'

export default function Home() {
  const { Home } = useContext(ThemeContext)
  const locationId = useSelector(dispatch.restaurant.getSelectedLocationId)
  return <Home.HomeView locationId={locationId} />
}
