import React, { useContext } from 'react'
import { FText, FView } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function SummaryRewardItemView({ count, name, productId, totalDiscountSubtotal, CancelButtonElement }) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView ml={'1rem'} minHeight={'3rem'}>
      <div className={classes.container}>
        <FView center size={'2.2rem'} bg={themeColors.grey}>
          <FText h8 textCenter white className={classes.count}>
            x{count}
          </FText>
        </FView>
        <FView mh={'1rem'}>
          <FontAwesomeIcon icon="award" style={{ fontSize: '2rem' }} color={themeColors.grey} />
        </FView>
        <FText h7 grey className={classes.name} bold>
          {name}
        </FText>
        <FText h7 backgroundText>
          {productId || !totalDiscountSubtotal ? (
            <FText h7 grey bold>
              FREE
            </FText>
          ) : (
            <FText h7 grey bold>{`-$${totalDiscountSubtotal.toFixed(2)}`}</FText>
          )}
        </FText>
        <FView size={'1rem'} />
        {CancelButtonElement}
      </div>
    </FView>
  )
}

const classes = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  count: css({
    minWidth: '2rem',
  }),
  name: css({
    flex: 1,
  }),
}
