import React, { useContext } from 'react'
import { FBSubscribe } from 'f-web-app/components'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'

export default function OrderStatus(props) {
  const { Components } = useContext(ThemeContext)
  const orderDoc = dispatch.user.getOrderDoc({ orderId: props.orderId })
  return (
    <FBSubscribe
      doc={orderDoc}
      onError={(error) => {
        console.warn(error)
        alert(error.message)
      }}
    >
      {({ loading, data: orderDetails }) => <Components.OrderStatusView loading={loading} {...(orderDetails || {})} />}
    </FBSubscribe>
  )
}
