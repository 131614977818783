import React, { useContext } from 'react'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'f-web/src/styles'
import { useIsTop } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function LogoView({ fixedHeight, className }) {
  const isTop = useIsTop()
  const { themeImages } = useContext(ThemeContext)
  return (
    <img className={css(isTop || fixedHeight ? classes.logoTop : classes.logo, className)} src={themeImages.logo} alt="logo" />
  )
}

const classes = {
  logoTop: css({
    cursor: 'pointer',
    height: '5rem',
    objectFit: 'contain',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    [MEDIA_QUERY.MIN_MD]: {
      height: '8rem',
    },
  }),
  logo: css({
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    cursor: 'pointer',
    height: '0rem',
    objectFit: 'contain',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MIN_MD]: {
      height: '5rem',
    },
  }),
}
