import React, { useContext } from 'react'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'
import { useSelector } from 'react-redux'

export default function Summary() {
  const { Cart } = useContext(ThemeContext)
  const cart = useSelector(dispatch.user.getCartWithProductDetails)
  const promos = useSelector(dispatch.user.getValidPromosWithDetails)
  const subTotal = useSelector(dispatch.user.getCartSubTotal)
  const tax = useSelector(dispatch.user.getCartTax)
  const tipAmount = useSelector(dispatch.user.getTipAmount)
  const total = useSelector(dispatch.user.getCartTotal)
  const orderType = useSelector(dispatch.user.getOrderType)
  const deliveryFee = useSelector(dispatch.user.getDeliveryFee)
  return (
    <Cart.SummaryView
      CartItemListElement={
        <>
          {Object.keys(cart).map(cartItemId => {
            return <Cart.Summary.CartItem key={cartItemId} cartItemId={cartItemId} {...cart[cartItemId]} />
          })}
          {Object.keys(promos).map(id => {
            return <Cart.Summary.RewardItem key={id} productId={id} {...promos[id]} />
          })}
        </>
      }
      CartTotalElement={
        <Cart.SummaryTotalView
          subTotal={subTotal}
          tipAmount={tipAmount}
          tax={tax}
          total={total}
          orderType={orderType}
          deliveryFee={deliveryFee}
          subTotalFormatted={subTotal.toFixed(2)}
          taxFormatted={tax.toFixed(2)}
          totalFormatted={total.toFixed(2)}
        />
      }
    />
  )
}
