import React, { useContext } from 'react'
import { FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function SummaryView({ CartItemListElement, CartTotalElement }) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView>
      {CartItemListElement}
      <FView mv="1rem" h={1} bg={themeColors.lightGrey} />
      {CartTotalElement}
    </FView>
  )
}
