import React, { useContext } from 'react'
import { useMediaMinLG } from 'f-web/src/hooks'
import { FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function MenuListView({ loading, children }) {
  const { Components, themeColors } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()
  if (loading) {
    return (
      <FView fill center>
        <Components.Spinner color={themeColors.backgroundText} />
      </FView>
    )
  }
  return (
    <FView
      mt={'2rem'}
      h="calc(95vh - 10rem)"
      relative
      row={isMinLG}
      maxWidth={1024}
      selfCenter={isMinLG}
      bg={isMinLG ? themeColors.white : themeColors.background}
      rounded
    >
      {children}
    </FView>
  )
}
