import React from 'react'
import { css } from 'emotion'
import { FText, FView } from 'components'
import { smallLogo } from 'images'
import { COLOR, MEDIA_QUERY } from 'styles'
import { useMediaMinLG } from 'f-web/src/hooks'

export default function CategoryItemView({ imageUrl, isSelected, name, index }) {
  const isMinLG = useMediaMinLG()
  const isEven = index % 2 === 0
  return (
    <FView className={classes.container}>
      <div className={classes.imageContainer}>
        {!!imageUrl ? (
          <img className={isEven ? classes.imageRight : classes.imageLeft} src={imageUrl} alt="Category" />
        ) : (
          <img className={classes.logo} src={smallLogo} alt="Recent Orders" />
        )}
        <div
          className={isEven ? classes.imageCoverRight : classes.imageCoverLeft}
          style={isSelected ? { backgroundColor: 'transparent' } : {}}
        />
      </div>
      <FText
        heading
        h6
        color={isSelected || !isMinLG ? 'white' : 'rgba(255,255,255,0.7)'}
        textCenter
        style={{ zIndex: 1, width: '78%' }}
      >
        {name ? name.toUpperCase() : ''}
      </FText>
    </FView>
  )
}

const classes = {
  container: css({
    alignItems: 'center',
    cursor: 'pointer',
    height: '50vw',
    width: '50vw',
    maxHeight: 190,
    maxWidth: 200,
    '&:hover': {
      transform: 'scale(1.03)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
  imageContainer: css({
    height: '83%',
    width: '83%',
    position: 'relative',
    maxHeight: 145,
    overflow: 'hidden',
  }),
  imageLeft: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 30,
    position: 'absolute',
  }),
  imageRight: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    position: 'absolute',
  }),
  logo: css({
    width: '100%',
    objectFit: 'contain',
    position: 'absolute',
  }),
  imageCoverLeft: css({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 30,
    [MEDIA_QUERY.MIN_LG]: {
      backgroundColor: COLOR.black25,
    },
  }),
  imageCoverRight: css({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
    borderBottomRightRadius: 30,
    borderTopLeftRadius: 30,
    [MEDIA_QUERY.MIN_LG]: {
      backgroundColor: COLOR.black25,
    },
  }),
}
