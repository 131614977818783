import { COLOR } from 'f-web-app/styles'

export { default as Payment } from './Payment'
export { default as PaymentView } from './PaymentView'
export { default as SubmitButtonView } from './SubmitButtonView'

export const cardNumberInputProps = {
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}

export const expiryInputProps = {
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}

export const CVCInputProps = {
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}

export const PostalInputProps = {
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}
