import React, { useContext } from 'react'
import { FText, FView } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

const ProductRowView = ({ count, name, priceFormatted, modifierItems }) => {
  const { themeColors } = useContext(ThemeContext)
  return (
    <>
      <div className={classes.productItem}>
        <FView center size={'2.2rem'} bg={themeColors.grey}>
          <FText h8 textCenter white className={classes.count}>
            x{count}
          </FText>
        </FView>
        <FView size={'1rem'} />
        <FText h7 grey className={classes.name} bold>
          {name}
        </FText>
        <FText bold h7 grey>{` $${priceFormatted}`}</FText>
      </div>
      <FView pl={'3.5rem'}>
        {!!modifierItems &&
          Object.entries(modifierItems).map(([itemId, item]) => {
            const { name, price, defaultValue, isSelected } = item
            if (defaultValue === isSelected) {
              return null
            }
            const modifierItemPriceStr = price === 0 ? '' : ` (${price < 0 ? '-' : ''}$${Math.abs(price).toFixed(2)})`
            return (
              <FText h8 key={itemId} color="#787878">
                {`• ${!isSelected ? 'No ' : ''}${name}${modifierItemPriceStr}`}
              </FText>
            )
          })}
      </FView>
    </>
  )
}

const classes = {
  productItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  }),
  count: css({
    minWidth: '3rem',
  }),
  name: css({
    flex: 1,
  }),
}

export default ProductRowView
