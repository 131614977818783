import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function Hero({ appDownloadLink, title }) {
  const { Home, themeImages } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()
  const isMinMD = useMediaMinMD()
  return (
    <FView
      h={isMinMD ? 1000 : 600}
      className={css(classes.container, {
        background: `url(${isMinLG ? themeImages.heroBG : themeImages.heroBGMobile}) no-repeat`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
      })}
    >
      <FView h={isMinMD ? 200 : 155} />
      <FView alignCenter>
        <FText h5 h4={isMinLG} black heading bold textCenter className={classes.title}>
          {title}
        </FText>
        <FView h={'2.5rem'} />
        <FView row={isMinMD}>
          <Home.OrderNowButton heading />
          <FView size={'1.5rem'} />
          <Home.DownloadAppButton heading link={appDownloadLink} />
        </FView>
      </FView>
    </FView>
  )
}

const classes = {
  container: css({
    width: '100%',
    position: 'relative',
    backgroundAttachment: 'fixed',
  }),
  title: css({
    width: 637,
    maxWidth: '100%',
  }),
}
