import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

const TextButtonView = ({ label, color }) => {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView alignStart>
      <FView bc={color ? color : themeColors.lightGrey} bw={0} bbw={1} cursor="pointer" bg={themeColors.transparent}>
        <FText h7 lightGrey color={color}>
          {label}
        </FText>
      </FView>
    </FView>
  )
}

export default TextButtonView
