import React, { useContext } from 'react'
import { FView, Space, FText } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'
import { ThemeContext } from 'f-web-app'

const PaymentView = ({
  CardNumberInputElement,
  ExpiryInputElement,
  CVCInputElement,
  PostalInputElement,
  SubmitButtonElement,
}) => {
  const { themeColors, themeImages, Components } = useContext(ThemeContext)
  return (
    <FView
      minHeight={'100vh'}
      bg={themeColors.background}
      style={
        themeImages.backgroundImage && {
          background: `url(${themeImages.backgroundImage}) center center / cover no-repeat`,
        }
      }
    >
      <Components.PageHeader relative fixedHeight />
      <FView className={classes.content}>
        <div className={classes.border}>
          <Components.Card>
            <div className={classes.container}>
              <FText h5 black bold>
                {'CREDIT / DEBIT CARD'}
              </FText>
              <Space h2 />
              {CardNumberInputElement}
              <Space h3 />
              <div className={classes.expirycvc}>
                {ExpiryInputElement}
                <Space w2 />
                {CVCInputElement}
              </div>
              <Space h3 />
              <div className={classes.postal}>{PostalInputElement}</div>
              <Space h3 />
              {SubmitButtonElement}
            </div>
          </Components.Card>
        </div>
      </FView>
    </FView>
  )
}

const classes = {
  content: css({
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }),
  border: css({
    width: '60rem',
    padding: '4rem 0 10rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      width: '100%',
      padding: '2rem',
    },
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  expirycvc: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  postal: css({
    width: 'calc(50% - 10px)',
  }),
}

export default PaymentView
