import FormData from 'form-data'

async function authFetch({ authToken, url, method, ...restParams }) {
  const response = await fetch(url, {
    method: method || 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(restParams),
  })
  const text = await response.text()
  let json
  try {
    json = JSON.parse(text)
  } catch (e) {
    if (response.ok) {
      return text
    } else {
      throw new Error(text)
    }
  }
  if (response.ok) {
    return json
  } else {
    throw new Error(json.message)
  }
}

export async function createOrderAndCharge(serverUrl, authToken, restaurantId, locationId, order) {
  return await authFetch({
    authToken,
    url: `${serverUrl}/api/create_order`,
    ...order,
    restaurantId,
    locationId,
  })
}

export async function createCustomerWithStripe(serverUrl, authToken, cardToken) {
  return await authFetch({
    authToken,
    url: `${serverUrl}/api/create_customer`,
    cardToken,
  })
}

export async function updateCustomerWithStripe(serverUrl, authToken, cardToken) {
  return await authFetch({
    authToken,
    method: 'PUT',
    url: `${serverUrl}/api/update_customer`,
    cardToken,
  })
}

export async function addCardToVaultWithCC({ card, serverUrl, authToken }) {
  const { number, cvc, exp_month, exp_year, address_zip } = card

  return await authFetch({
    authToken,
    method: 'POST',
    url: `${serverUrl}/_api/v2/add_card_with_cc`,
    pan: number,
    expdate: String(exp_year).slice(2, 4) + ('0' + exp_month).slice(-2),
    cvd_value: cvc,
    avs_zipcode: address_zip,
  })
}

export async function addCard({ card, hptId, monerisUrl, serverUrl, authToken }) {
  const { number, cvc, exp_month, exp_year, address_zip } = card

  const formdata = new FormData()
  formdata.append('input_data', number)
  formdata.append('input_exp', ('0' + exp_month).slice(-2) + String(exp_year).slice(2, 4))
  formdata.append('input_cvd', cvc)
  formdata.append('hpt_id', hptId)
  formdata.append('doTokenize', 'true')

  const requestOptions = {
    method: 'POST',
    headers: {
      'User-Agent': 'PHP NA - 1.0.16',
    },
    body: formdata,
    redirect: 'follow',
  }
  const response = await fetch(monerisUrl, requestOptions)
  const resultJson = await response.json()
  if (resultJson.responseCode[0] === '001') {
    // Success
    return await authFetch({
      authToken,
      method: 'POST',
      url: `${serverUrl}/api/add_card`,
      temp_data_key: resultJson.dataKey,
      avs_zipcode: address_zip,
    })
  } else {
    throw new Error('Cannot add Card')
  }
}
