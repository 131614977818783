import { db } from 'f-core/src/config/firebase'

export const getOrderHistoryRange = ({
  restaurantId,
  userId,
  fromDate,
  toDate,
  limit,
}) => {
  return new Promise((resolve, reject) => {
    let docRef = db
      .collection('Restaurants')
      .doc(restaurantId)
      .collection('Orders')
      .where('userId', '==', userId)

    if (fromDate) {
      docRef = docRef.where('createdAt', '>', fromDate.valueOf())
    }
    if (toDate) {
      docRef = docRef.where('createdAt', '<', toDate.valueOf())
    }

    docRef
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get()
      .then(docs => {
        const orders = []
        docs.forEach(doc => {
          const orderData = doc.data()
          orderData.id = doc.id
          orders.push(orderData)
        })
        resolve(orders)
      })
      .catch(reject)
  })
}

export function updateOrder({ restaurantId, orderId, order }) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')
    .doc(orderId)
    .update(order)
}
