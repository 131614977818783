import React, { Component } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { MEDIA_QUERY } from 'f-web/src/styles'

class PrimaryButtonView extends Component {
  render() {
    const { className, disabled, label } = this.props
    const classNames = css(disabled ? styles.disabled : styles.button, className)
    return (
      <FView center className={classNames}>
        <FText content h4 white={!disabled} disabled={disabled} bold>
          {label}
        </FText>
      </FView>
    )
  }
}

const styles = {
  button: css({
    paddingLeft: '6rem',
    paddingRight: '6rem',
    height: '5rem',
    background: 'rgba(0,0,0,0.25)',
    border: '3px solid #FFFFFF',
    borderRadius: '0.8rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.25)',
    },
    [MEDIA_QUERY.SM]: {
      paddingLeft: '4rem',
      paddingRight: '4rem',
    },
  }),
  disabled: css({
    paddingLeft: '6rem',
    paddingRight: '6rem',
    height: '5rem',
    background: 'rgba(0,0,0,0.4)',
    border: '3px solid rgba(255,255,255,0.6)',
    borderRadius: '0.8rem',
    cursor: 'pointer',
    [MEDIA_QUERY.SM]: {
      paddingLeft: '4rem',
      paddingRight: '4rem',
    },
  }),
}

export default PrimaryButtonView
