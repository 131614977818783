import React, { Component } from 'react'
import { css } from 'emotion'
import { Space, FText, FView } from 'components'
import * as images from 'images'

class Promotions extends Component {
  render() {
    return (
      <FView className={classes.promoBG}>
        <div className={classes.container}>
          <FView className={classes.promoContainer} shadow>
            <FView fill p={'3rem'}>
              <FText h3 white textCenter heading>
                POKE BOWL
              </FText>
              <Space h3 />
              <FText h6 white textCenter>
                Try our most popular dish Poke Bowl, topped with Avocado, Pineapple, Wakame Salad, Green Beans, Kale,
                and Howe Special Sauce.
              </FText>
            </FView>
            <img className={classes.image} src={images.pokeBowl} alt="Promo 1" />
          </FView>
          <FView className={classes.promoContainer} shadow>
            <FView fill p={'3rem'}>
              <FText h3 white textCenter heading>
                NIGIRI SUSHI
              </FText>
              <Space h3 />
              <FText h6 white textCenter>
                Our Nigiri Sushi is always made with fresh fish. Upgrade to Aburi Nigiri for an extra flavour.
              </FText>
            </FView>
            <img className={classes.image} src={images.promotion1} alt="Promo 2" />
          </FView>
          <FView className={classes.promoContainer} shadow>
            <FView fill p={'3rem'}>
              <FText h3 white textCenter heading>
                VOLCANO ROLL
              </FText>
              <Space h3 />
              <FText h6 white textCenter>
                Volcano Roll is our most popular Howe Special Roll. Try one today and show off to your Instagram
                friends.
              </FText>
            </FView>
            <img className={classes.image} src={images.promotion3} alt="Promo 3" />
          </FView>
        </div>
      </FView>
    )
  }
}

const classes = {
  promoBG: css({
    width: '100%',
    backgroundImage: `url(${images.promoBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    backgroundSize: '100% 300px',
    alignItems: 'center',
  }),
  container: css({
    width: 1024,
    maxWidth: '100%',
    height: '48rem',
    marginTop: '6rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflowX: 'auto',
    overflowY: 'hidden',
  }),
  promoContainer: css({
    minWidth: 300,
    width: 320,
    marginLeft: '1rem',
    marginRight: '1rem',
    backgroundColor: '#171717',
  }),
  image: css({
    width: '100%',
    objectFit: 'contain',
  }),
}

export default Promotions
