import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function PaymentMethodView({
  OnlineCCButtonElement,
  InpersonCashButtonElement,
  InpersonCCButtonElement,
}) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <>
      <FView size={15} />
      <FText h7 bold color="black">
        PAYMENT METHOD:
      </FText>
      {OnlineCCButtonElement && (
        <>
          <FView size={10} />
          <FText h7 bold grey>
            Pay Online:
          </FText>
          <FView h={1} bg={themeColors.lightGrey50} mv={10} />
          <FView alignStart>{OnlineCCButtonElement}</FView>
        </>
      )}
      {(InpersonCashButtonElement || InpersonCCButtonElement) && (
        <>
          <FView size={15} />
          <FText h7 bold grey>
            Pay In-Person:
          </FText>
          <FView h={1} bg={themeColors.lightGrey50} mv={10} />
          <FView row justifyBetween maxWidth={280}>
            {InpersonCashButtonElement}
            {InpersonCCButtonElement}
          </FView>
        </>
      )}
      <FView size={15} />
    </>
  )
}
