import React, { useContext } from 'react'
import { FButton } from 'f-web-app/components'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'
import { css } from 'emotion'

export default function RewardItem({ id, name, count, productId, totalDiscountSubtotal }) {
  const { Cart, Components } = useContext(ThemeContext)
  return (
    <Cart.SummaryRewardItemView
      count={count}
      name={name}
      productId={productId}
      totalDiscountSubtotal={totalDiscountSubtotal}
      CancelButtonElement={
        <FButton
          onClick={() => {
            dispatch.user.removePromo(id)
            dispatch.notification.setMessage({
              message: `${name} unredeemed!`,
              level: 'success',
            })
          }}
          className={classes.cancelButton}
        >
          <Components.CancelButtonView />
        </FButton>
      }
    />
  )
}

const classes = {
  cancelButton: css({
    width: '4rem',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}
