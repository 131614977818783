import React, { useState, useContext } from 'react'
import { get, pick } from 'lodash'
import { useSelector } from 'react-redux'
import { css } from 'emotion'
import { dispatch } from 'f-web-app/store'
import { useMediaMinSM, useMediaMinLG } from 'f-web/src/hooks'
import { FButton, FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function MenuList() {
  const { Menu, Components } = useContext(ThemeContext)
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const [isCategorySelected, setIsCategorySelected] = useState(false)
  const loading = useSelector(dispatch.restaurant.getLoading)
  const categories = useSelector(dispatch.restaurant.getCategories)
  const categoryOrder = useSelector(dispatch.restaurant.getActiveCategoryOrder)
  const recentOrders = useSelector(dispatch.user.getRecentOrdersSorted)
  const products = useSelector(dispatch.restaurant.getProducts)
  const isMinSM = useMediaMinSM()
  const isMinLG = useMediaMinLG()
  const productOrder = dispatch.restaurant.getProductOrder(selectedCategoryId)
  let categoryProducts
  if (selectedCategoryId === 'recent') {
    categoryProducts = Object.values(pick(products, recentOrders))
  } else {
    categoryProducts = productOrder.reduce((prev, productId) => {
      const product = products[productId]
      if (product) {
        prev.push(product)
      }
      return prev
    }, [])
  }
  if (!selectedCategoryId && dispatch.restaurant.getActiveCategoryOrder()[0]) {
    setSelectedCategoryId(dispatch.restaurant.getActiveCategoryOrder()[0])
  }
  const onCategorySelect = categoryId => {
    setSelectedCategoryId(categoryId)
    setIsCategorySelected(true)
  }
  const categoriesInOrder = categoryOrder.reduce(
    (prev, categoryId) => {
      if (categories[categoryId]) {
        prev.push(categories[categoryId])
      }
      return prev
    },
    recentOrders.length > 0 ? [{ id: 'recent', name: 'Recent Orders', nameFormatted: 'RECENT ORDERS' }] : []
  )
  return (
    <Menu.MenuListView loading={loading}>
      {!isMinLG && isCategorySelected && (
        <FView selfCenter w={425} maxWidth="100%" ph={'1rem'}>
          <FButton onClick={() => setIsCategorySelected(false)}>
            <Menu.BackButtonView />
          </FButton>
        </FView>
      )}
      <FView
        overflowY="auto"
        h="calc(95vh - 10rem)"
        className={classes.animate}
        absolute={!isMinLG}
        transform={isMinLG ? 0 : isCategorySelected ? 'translateX(-100vw)' : 0}
        row
        wrap
        selfCenter={!isMinLG}
        maxWidth={425}
        pt={'2rem'}
      >
        <Components.StoreClosedBanner />
        {categoriesInOrder.map((category, index) => {
          return (
            <FButton key={category.id} onClick={() => onCategorySelect(category.id)}>
              <Menu.CategoryItemView
                imageUrl={category.imageUrl}
                isSelected={selectedCategoryId === category.id}
                name={category.name}
                index={index}
              />
            </FButton>
          )
        })}
      </FView>
      <FView
        className={classes.animate}
        h={isMinLG ? 'calc(95vh - 10rem)' : 'calc(95vh - 15rem)'}
        top={40}
        w={599}
        absolute={!isMinLG}
        transform={isMinLG ? 0 : isCategorySelected ? 0 : 'translateX(100vw)'}
        selfCenter={!isMinLG}
        maxWidth={!isMinLG ? (isMinSM ? 425 : '100%') : null}
      >
        {categoryProducts.length < 1 ? (
          <Menu.EmptyCategoryView />
        ) : (
          <FView fill overflowY="auto">
            <FView block p={isMinLG ? '2.5rem' : '1rem'}>
              <Menu.CategoryDescriptionView description={get(categories[selectedCategoryId], 'description', '')} />
              {categoryProducts.map(product => {
                const { id } = product
                return (
                  <FView w={'100%'} key={id}>
                    <Menu.Products.ProductItem {...product} />
                  </FView>
                )
              })}
            </FView>
          </FView>
        )}
      </FView>
    </Menu.MenuListView>
  )
}

const classes = {
  animate: css({
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
}
