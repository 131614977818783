import React, { useContext, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from 'f-web-app/store'
import { FLink, FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'
import { moment } from 'f-utils'
import { useMediaMinLG } from 'f-web/src/hooks'

export default function ContactUs({ appStoreUrl, googlePlayUrl }) {
  const address = useSelector(dispatch.restaurant.getAddress)
  const phoneNumber = useSelector(dispatch.restaurant.getFormattedPhoneNumber)
  const email = useSelector(dispatch.restaurant.getEmail)
  const hoursLT = useSelector(dispatch.restaurant.getHoursLT)
  const { themeImages, Home } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()

  return (
    <FView row={isMinLG} selfCenter w={1024} maxWidth={'100%'}>
      <FView selfCenter w={415} maxWidth="100%">
        <img src={themeImages.contactUsMap} alt="map" style={{ objectFit: 'contain', maxWidth: '100%' }} />
      </FView>
      <FView size={'2.5rem'} />
      <FView mh={13} alignCenter={!isMinLG} selfCenter w={280}>
        <FText bold h5 primary>
          CONTACT US
        </FText>
        <FView size={'1.5rem'} />
        <FView h={'21rem'}>
          {address && (
            <FView row mb="2rem">
              <FView w="8rem">
                <FText bold h7 backgroundText>
                  Address
                </FText>
              </FView>
              <FText bold h7 backgroundText numberOfLines={2} style={{ width: 170 }}>
                {address}
              </FText>
            </FView>
          )}
          {phoneNumber && (
            <FView row mb="2rem">
              <FView w="8rem">
                <FText bold h7 backgroundText>
                  Phone
                </FText>
              </FView>
              <FLink href={`tel:${phoneNumber}`}>
                <FText bold h7 backgroundText>
                  {phoneNumber}
                </FText>
              </FLink>
            </FView>
          )}
          {email && (
            <FView row>
              <FView w="8rem">
                <FText bold h7 backgroundText>
                  Email
                </FText>
              </FView>
              <FView fill>
                <FLink href={`mailto:${email}`}>
                  <FText bold h7 backgroundText style={{ wordBreak: 'break-all' }}>
                    {email}
                  </FText>
                </FLink>
              </FView>
            </FView>
          )}
        </FView>
        <FView size={'2.5rem'} />
        {/* <Home.ContactUsDownloadAppView appStoreUrl={appStoreUrl} googlePlayUrl={googlePlayUrl} /> */}
      </FView>
      <FView size={'2.5rem'} />
      <FView alignCenter={!isMinLG}>
        <FText bold h5 primary>
          ORDERING HOURS
        </FText>
        <FView size={'1.5rem'} />
        <FView>
          {[1, 2, 3, 4, 5, 6, 0].map((day) => {
            return (
              <Fragment key={day}>
                <FView row w="100%">
                  <FView w="8rem">
                    <FText bold h7 backgroundText>
                      {moment().weekday(day).format('ddd')}
                    </FText>
                  </FView>
                  <FView>
                    {hoursLT && hoursLT[day] ? (
                      hoursLT[day].map(({ openLT, closeLT }, index) => {
                        if (openLT == null || closeLT == null) {
                          return (
                            <FText red h7 bold key={String(day) + String(index)}>
                              Closed
                            </FText>
                          )
                        } else {
                          return (
                            <FText bold h7 backgroundText key={String(day) + String(index)}>
                              {openLT} - {closeLT}
                            </FText>
                          )
                        }
                      })
                    ) : (
                      <FText red h6 bold>
                        Closed
                      </FText>
                    )}
                  </FView>
                </FView>
                <FView h={'1.5rem'} />
              </Fragment>
            )
          })}
        </FView>
      </FView>
    </FView>
  )
}
