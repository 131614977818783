import React, { useContext } from 'react'
import { FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

export default function UserDetailsModalView({
  NameInputElement,
  PhoneInputElement,
  CancelButtonElement,
  SubmitButtonElement,
}) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView className={styles.container} rounded bg={themeColors.background} p="2rem">
      <FView mb="2rem">
        <FText h6 textCcenter backgroundText>
          To complete your order, enter the following:
        </FText>
      </FView>
      {NameInputElement && <FView mb="2rem">{NameInputElement}</FView>}
      {PhoneInputElement && <FView mb="2rem">{PhoneInputElement}</FView>}
      <FView row selfCenter mt="2rem">
        {CancelButtonElement}
        <FView size={'1rem'} />
        {SubmitButtonElement}
      </FView>
    </FView>
  )
}

const styles = {
  container: css({
    width: '60rem',
    maxWidth: '100vw',
  }),
}
