import * as Rewards from './Rewards'
import * as Summary from './Summary'
import { COLOR } from 'f-web-app/styles'

export { Rewards, Summary }
export { default as Cart } from './Cart'
export { default as OrderComplete } from './OrderComplete'

// Presentation
export { default as AddPaymentButtonView } from './AddPaymentButtonView'
export { default as CartView } from './CartView'
export { default as DeliveryModal } from './DeliveryModal'
export { default as FinishOrderButtonView } from './FinishOrderButtonView'
export { default as LoginButtonView } from './LoginButtonView'
export { default as PlaceFreeOrderButtonView } from './PlaceFreeOrderButtonView'
export { default as PlaceOrderButtonView } from './PlaceOrderButtonView'
export { default as PaymentMethodView } from './PaymentMethodView'
export { default as OrderCompleteView } from './OrderCompleteView'
export { default as UserDetailsModalView } from './UserDetailsModalView'

export { default as RewardsLeftArrowButtonView } from './RewardsLeftArrowButtonView'
export { default as RewardsLoadedView } from './RewardsLoadedView'
export { default as RewardsRedeemButtonView } from './RewardsRedeemButtonView'
export { default as RewardsRightArrowButtonView } from './RewardsRightArrowButtonView'
export { default as RewardItemView } from './RewardItemView'

export { default as SummaryRewardItemView } from './SummaryRewardItemView'
export { default as SummaryContainerView } from './SummaryContainerView'
export { default as SummaryTotalView } from './SummaryTotalView'
export { default as SummaryView } from './SummaryView'

export const notesInputProps = {
  placeholder: 'Enter Special Requests \nEx. Remove cucumber from BC Roll',
  maxLength: '128',
  style: { boxShadow: 'none', border: '1px solid ' + COLOR.grey50 },
}

export const nameInputProps = {
  placeholder: 'Enter Name',
}

export const phoneInputProps = {
  placeholder: 'Enter Phone Number',
}
