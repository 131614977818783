// ===================== THEME IMAGES ====================
// MENU
export { default as addProductModalBG } from './addProductModalBG.svg'

export const logo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/foodlyLogo-Large.png'
export const icon =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/foodly-square-logo.png'

// HERO
export const heroBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/heroBG.png'
export const heroBGMobile =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/heroBGMobile.png'

// GALLERY
export const gallery01 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/gallery01.png'
export const gallery02 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/gallery02.png'
export const gallery03 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/gallery03.png'
export const gallery04 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/gallery04.png'
export const gallery05 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/gallery05.png'
export const gallery06 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/gallery06.png'
export const galleryTitle =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/foodly/galleryTitle.png'

// CONTACT US
export const contactUsMap =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/contactUsMap.png'
export const appStore = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good,w_130/app-store.png'
export const googlePlay = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good,w_130/google-play.png'

// REWARD
export const pointsMeter =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/rewardArrowRight.png'
export const rewardBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v4/foodly/rewardBG.png'
export const rewardBorder =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/foodly/rewardBorder.png'

// =======================================================
