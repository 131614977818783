import React, { useContext } from 'react'
import { ThemeContext } from 'f-web-app'
import { FView } from 'f-web-app/components'

export default function SubmitButtonView({ saving, disabled }) {
  const { Components } = useContext(ThemeContext)
  return (
    <FView alignCenter>
      <Components.PaintedButtonView disabled={disabled} label={saving ? 'PROCESSING' : 'ADD CARD'} />
    </FView>
  )
}
