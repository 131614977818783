import React, { useContext } from 'react'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

export default function RewardsRightArrowButtonView({ disabled }) {
  const { themeImages } = useContext(ThemeContext)
  return (
    <img
      className={styles.rightArrow}
      src={themeImages.rewardArrowRight}
      style={disabled ? { opacity: 0.5 } : {}}
      alt="Right Arrow"
    />
  )
}

const styles = {
  rightArrow: css({
    position: 'absolute',
    top: '45%',
    right: '.8rem',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
}
