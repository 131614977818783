import React, { useContext } from 'react'
import { ThemeContext } from 'f-web-app'
import { FView } from 'f-web-app/components'

export default function UserView({ children }) {
  const { themeColors, themeImages, Components } = useContext(ThemeContext)
  return (
    <FView
      minHeight="100vh"
      bg={themeColors.background}
      style={
        themeImages.backgroundImage && {
          background: `url(${themeImages.backgroundImage}) center center / cover no-repeat`,
        }
      }
    >
      <Components.PageHeader fixedHeight relative />
      {children}
    </FView>
  )
}
