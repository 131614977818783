import React, { useContext } from 'react'
import { get, pick } from 'lodash'
import { dispatch } from 'f-web-app/store'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'f-web-app'

export default function Products({ categoryId }) {
  const categories = useSelector(dispatch.restaurant.getCategories)
  const products = useSelector(dispatch.restaurant.getProducts)
  const recentOrders = useSelector(dispatch.user.getRecentOrdersSorted)
  const { Menu } = useContext(ThemeContext)

  let categoryProducts
  if (categoryId === 'recent') {
    categoryProducts = Object.values(pick(products, recentOrders))
  } else {
    const productOrder = dispatch.restaurant.getProductOrder(categoryId)
    categoryProducts = productOrder.reduce((prev, productId) => {
      const product = products[productId]
      if (product) {
        prev.push(product)
      }
      return prev
    }, [])
  }
  if (categoryId === 'recent' && categoryProducts.length < 1) {
    return <Menu.EmptyRecentOrderView />
  }
  return (
    <Menu.ProductsItemContainerView
      categoryDescription={get(categories[categoryId], 'description', '')}
      ProductsListElement={categoryProducts.map(product => {
        const { id } = product
        return <Menu.Products.ProductItem key={id} {...product} />
      })}
    />
  )
}
