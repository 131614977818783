import React, { PureComponent, Fragment } from 'react'
import { Provider } from 'react-redux'
import Routes from './routes'
import { store, dispatch } from 'f-web-app/store'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAward,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faMapMarker,
  faMinusCircle,
  faPlusCircle,
  faShoppingCart,
  faSignInAlt,
  faSpinner,
  faTimesCircle,
  faUser,
  faUtensils,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { FSelect, Notification, SubscribeRestaurantLocation, Renderless } from 'f-web-app/components'

library.add(
  faAward,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faMapMarker,
  faMinusCircle,
  faPlusCircle,
  faShoppingCart,
  faSignInAlt,
  faSpinner,
  faTimesCircle,
  faUser,
  faUtensils,
  faStar
)

export default class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <Fragment>
          <Routes />
          <Notification />
          <SubscribeRestaurantLocation />
          <FSelect selectorNot={dispatch.restaurant.getLoading}>
            <Renderless
              setInterval={dispatch.restaurant.updateActiveCategoryOrder}
              setIntervalCallOnMount
              setIntervalms={60000}
            />
          </FSelect>
        </Fragment>
      </Provider>
    )
  }
}
