import React from 'react'
import { css } from 'emotion'
import { FLink, FView, FText } from 'components'
import { foodlyLogo } from 'images'

const year = new Date().getFullYear()

export default function Footer() {
  return (
    <FView className={classes.container}>
      <FText h8 backgroundText textCenter>
        COPYRIGHT &copy; {year} FOODLY TECHNOLOGIES INC.
      </FText>
      <FLink href="https://foodly.ca">
        <img className={classes.image} src={foodlyLogo} alt="Foodly" />
      </FLink>
    </FView>
  )
}

const classes = {
  container: css({
    width: '100%',
    alignItems: 'center',
    marginBottom: '2rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  }),
  image: css({
    height: '2.5rem',
    objectFit: 'contain',
    marginTop: '1rem',
  }),
}
