import React from 'react'
import { FText } from 'components'
import { css } from 'emotion'

const OrderHistoryEmptyView = () => (
  <div className={styles.orderHistory} style={{ padding: '8rem 0 8rem 0' }}>
    <FText heading h4 bold black>
      No Orders Yet.
    </FText>
  </div>
)

const styles = {
  orderHistory: css({
    width: '100%',
    textAlign: 'center',
  }),
}

export default OrderHistoryEmptyView
