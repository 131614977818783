import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { useMediaMinSM, useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function AddProductModalView(props) {
  const { name, quantity } = props
  const { CloseModalButtonElement, DecreaseQtyButtonElement, IncreaseQtyButtonElement, ModifierGroupsElement } = props
  const { AddToCartButtonElement } = props

  const { themeColors } = useContext(ThemeContext)
  const isMinSM = useMediaMinSM()
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  return (
    <FView className={classes.modal}>
      <FView h={107} bg={themeColors.background} p={11}>
        <FView row>
          {!isMinLG && CloseModalButtonElement}
          <FView pr={!isMinLG && 19} pl={isMinLG && 19} fill center>
            <FText h7 h6={isMinSM} h5={isMinMD} bold backgroundText>
              {name}
            </FText>
          </FView>
          {isMinLG && CloseModalButtonElement}
        </FView>
        <FView row pt={11} fill center>
          <FView mr={25}>
            <FText h7 backgroundText>
              Quantity:
            </FText>
          </FView>
          {DecreaseQtyButtonElement}
          <FView w={40}>
            <FText h7 textCenter backgroundText>
              {quantity}
            </FText>
          </FView>
          {IncreaseQtyButtonElement}
        </FView>
      </FView>
      <FView fill bg="white" overflowY="auto">
        {ModifierGroupsElement}
      </FView>
      {AddToCartButtonElement}
    </FView>
  )
}

const classes = {
  modal: css({
    backgroundColor: '#FFF',
    width: 600,
    maxWidth: '100vw',
    overflow: 'hidden',
    height: '65rem',
    maxHeight: 'calc(100vh - 100px)',
  }),
}
