import React from 'react'
import { FView } from 'components'
import { css } from 'emotion'
import * as images from 'images'

const RewardsRedeemButtonView = () => (
  <FView className={styles.redeemButton}>
    <img src={images.redeemButton} alt="Redeem Button" />
  </FView>
)

const styles = {
  redeemButton: css({
    '&:hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
}

export default RewardsRedeemButtonView
