import React, { useContext } from 'react'
import { FButton } from 'f-web-app/components'
import useReactRouter from 'use-react-router'
import { ThemeContext } from 'f-web-app'

export default function Logo({ children }) {
  const { Components } = useContext(ThemeContext)
  const { history } = useReactRouter()
  return (
    <FButton onClick={() => history.push('/')}>
      <Components.LogoView />
    </FButton>
  )
}
