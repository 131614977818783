import React from 'react'
import { ThemeContext } from 'f-web-app'
import { FButton, FInput, FModal } from 'f-web-app/components'
import { firebaseAuth } from 'f-core/src/config/firebase'
import { dispatch } from 'f-web-app/store'

export default class ForgotPasswordButton extends React.PureComponent {
  emailInput = React.createRef()
  state = { showModal: false, submitting: false }
  render() {
    return (
      <ThemeContext.Consumer>
        {({ Auth, Components }) => (
          <>
            <FButton onClick={() => this.setState({ showModal: true })}>
              <Auth.ForgotPasswordButtonView />
            </FButton>
            <FModal isOpen={this.state.showModal} closeModal={() => this.setState({ showModal: false })}>
              <Auth.ForgotPasswordModalView
                EmailInputElement={
                  <FInput ref={this.emailInput} onKeyPress={this._handleKeyPress} {...Auth.emailInputProps} />
                }
                CancelButtonElement={
                  <FButton onClick={() => this.setState({ showModal: false })}>
                    <Components.PaintedButtonView label="CANCEL" style={{ width: '15rem' }} black />
                  </FButton>
                }
                SubmitButtonElement={
                  <FButton onClick={this._handleForgotPassword} disabled={this.state.submitting}>
                    <Components.PaintedButtonView
                      disabled={this.state.submitting}
                      label={'SUBMIT'}
                      style={{ width: '15rem' }}
                    />
                  </FButton>
                }
              />
            </FModal>
          </>
        )}
      </ThemeContext.Consumer>
    )
  }
  _handleKeyPress = e => {
    if (e.key === 'Enter') {
      this._handleForgotPassword(e)
    }
  }
  _handleForgotPassword = e => {
    e.preventDefault()
    const email = this.emailInput.current.value.trim()
    if (!email) {
      dispatch.notification.setMessage({
        message: `Email cannot be empty`,
        level: 'error',
      })
      return
    }
    this.setState({ submitting: true })
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch.notification.setMessage({
          message: `Password reset sent to ${email}`,
          level: 'success',
        })
      })
      .catch(e => {
        dispatch.notification.setMessage({ message: e.message, level: 'error' })
      })
      .finally(() => {
        this.setState({ submitting: false, showModal: false })
      })
  }
}
