import React, { useContext } from 'react'
import { css } from 'emotion'
import { FLink, FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function ContactUsDownloadAppView({ appStoreUrl, googlePlayUrl }) {
  const { themeImages } = useContext(ThemeContext)
  return (
    <>
      <FText bold h5 primary>
        DOWNLOAD APP
      </FText>
      <FView size={'1.5rem'} />
      <FView row justifyCenter>
        <FLink href={appStoreUrl}>
          <img className={classes.mobile} src={themeImages.appStore} alt="App Store" />
        </FLink>
        <FView size={'2rem'} />
        <FLink href={googlePlayUrl}>
          <img className={classes.mobile} src={themeImages.googlePlay} alt="Goodle Play" />
        </FLink>
      </FView>
    </>
  )
}

const classes = {
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
  }),
}
