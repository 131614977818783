import React, { useContext } from 'react'
import { FView } from 'f-web-app/components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'
import { ThemeContext } from 'f-web-app'

const CartView = ({ loading, CartSummaryOrOrderCompleteElement }) => {
  const { themeColors, Cart, Components } = useContext(ThemeContext)
  return (
    <FView minHeight="100vh" bg={themeColors.background} overflowY={'auto'}>
      <Components.PageHeader relative fixedHeight />
      {loading ? (
        <FView minHeight="80vh" center>
          <Components.Spinner color={themeColors.backgroundText} />
        </FView>
      ) : (
        <FView className={classes.content} center>
          <div className={classes.border}>
            <Cart.Rewards.Rewards />
            <FView size="4rem" />
            {CartSummaryOrOrderCompleteElement}
          </div>
        </FView>
      )}
    </FView>
  )
}

const classes = {
  content: css({
    width: '100%',
  }),
  border: css({
    display: 'flex',
    alignItems: 'center',
    width: '102.4rem',
    maxWidth: '100%',
    flexDirection: 'column-reverse',
    padding: '4rem 0rem 4rem 0rem',
    [MEDIA_QUERY.MIN_LG]: {
      paddingRight: '4rem',
      paddingLeft: '4rem',
      flexDirection: 'row',
    },
  }),
}

export default CartView
