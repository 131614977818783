export * from 'f-core/src/components'
export * from 'f-web/src/components'
export { default as MediaQuery } from 'react-responsive'

export { default as Card } from './Card'
export { default as FText } from './FText'
export { default as IconLink } from './IconLink'
export { default as FSelect } from './FSelect'
export { default as LocationSelect } from './LocationSelect'
export { default as Logo } from './Logo'
export { default as LogoView } from './LogoView'
export { default as Notification } from './Notification'
export { default as OrderStatus } from './OrderStatus'
export { default as OrderStatusView } from './OrderStatusView'
export { default as ReviewModal } from './ReviewModal'
export { default as StoreClosedBanner } from './StoreClosedBanner'
export { default as SubscribeRestaurantLocation } from './SubscribeRestaurantLocation'
export { default as FModal } from './FModal'
export { default as NavbarView } from './NavbarView'
export { default as PageHeader } from './PageHeader'

// Locations
export { default as LocationSelectNameView } from './Locations/LocationSelectNameView'
export { default as LocationSelectModalView } from './Locations/LocationSelectModalView'
export { default as LocationSelectButtonView } from './Locations/LocationSelectButtonView'

// Buttons
export { default as AddButton } from './Buttons/AddButton'
export { default as CancelButton } from './Buttons/CancelButton'
export { default as CheckButton } from './Buttons/CheckButton'
export { default as EditButton } from './Buttons/EditButton'
export { default as OrderButton } from './Buttons/OrderButton'
export { default as PrimaryButton } from './Buttons/PrimaryButton'
export { default as SubtractButton } from './Buttons/SubtractButton'
export { default as TextButton } from './Buttons/TextButton'

export { default as AddButtonView } from './AddButtonView'
export { default as CancelButtonView } from './CancelButtonView'
export { default as CheckButtonView } from './CheckButtonView'
export { default as EditButtonView } from './EditButtonView'
export { default as OrderButtonView } from './OrderButtonView'
export { default as PaintedButtonView } from './PaintedButtonView'
export { default as PrimaryButtonView } from './PrimaryButtonView'
export { default as SubtractButtonView } from './SubtractButtonView'
export { default as TextButtonView } from './TextButtonView'

export const reviewCommentInputProps = {
  placeholder: 'Comments',
}
