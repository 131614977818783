import React, { useContext } from 'react'
import { FButton, FView, FText } from 'f-web-app/components'
import { css } from 'emotion'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'

export default function CartItem({ cartItemId, count, name, price, selectedModifiers }) {
  const { themeColors, Components } = useContext(ThemeContext)
  const cartItemPrice = dispatch.user.getCartItemTotal({ selectedModifiers, count, price })
  const selectedModifierNames = Object.entries(selectedModifiers).reduce((prev, [modifierGroupId, modifierItemObj]) => {
    const modifierGroupDetails = dispatch.restaurant.getModifierGroupDetails(modifierGroupId)
    if (!modifierGroupDetails) {
      return prev
    }
    for (const [modifierItemId, isSelected] of Object.entries(modifierItemObj)) {
      if (!modifierGroupDetails.modifierItems[modifierItemId]) {
        continue
      }
      const modifierPrice = modifierGroupDetails.modifierItems[modifierItemId].price
      modifierGroupDetails.modifierItems[modifierItemId].defaultValue !== isSelected &&
        prev.push(
          `${!isSelected ? 'No ' : ''}${modifierGroupDetails.modifierItems[modifierItemId].name}${
            modifierPrice === 0 ? '' : ` (${modifierPrice < 0 ? '-' : ''}$${Math.abs(modifierPrice).toFixed(2)})`
          }`
        )
    }
    return prev
  }, [])
  return (
    <FView ml={'1rem'} minHeight={'3rem'}>
      <div className={classes.container}>
        <FView center size={'2.2rem'} bg={themeColors.grey}>
          <FText h8 textCenter white className={classes.count}>
            x{count}
          </FText>
        </FView>
        <FText h7 grey className={classes.name} bold>
          {name}
        </FText>
        <FText h7 grey bold>{`$${cartItemPrice === 0 ? 'FREE' : cartItemPrice.toFixed(2)}`}</FText>
        <FView w={10} />
        <FButton
          onClick={() => {
            dispatch.user.subtractCartItem({ cartItemId })
            dispatch.notification.setMessage({
              message: `${name} removed from Cart`,
              level: 'success',
            })
          }}
          className={classes.cancelButton}
        >
          <Components.CancelButtonView />
        </FButton>
      </div>
      {selectedModifierNames.map((modifierName, index) => (
        <FView key={index} ml={'4rem'}>
          <FText bold color={themeColors.grey} h8 numberOfLines={1}>
            • {modifierName}
          </FText>
        </FView>
      ))}
    </FView>
  )
}

const classes = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  name: css({
    flex: 1,
    marginLeft: '1rem',
  }),
  cancelButton: css({
    width: '4rem',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}
