import React, { useContext } from 'react'
import { css } from 'emotion'
import { FView, FText } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function CategoryDescriptionView({ description }) {
  const { themeColors } = useContext(ThemeContext)
  if (!description) {
    return null
  }
  return (
    <FView bg="white" bc={themeColors.lightGrey} bw={1} className={classes.description} rounded>
      <FText h6 bold grey>
        {description}
      </FText>
    </FView>
  )
}

const classes = {
  description: css({
    width: '100%',
    marginBottom: '1rem',
    padding: '1.5rem',
  }),
}
