import React from 'react'
import { FText, FView } from 'components'

const UserDetailsPaymentView = ({ last4, EditCCButtonElement }) => (
  <>
    <FText bold h7 black>
      Credit Card:
    </FText>
    <FView row alignCenter>
      <FView fill mt={'1rem'} h={'5.7rem'} justifyCenter pl={'1.5rem'} mr={'6rem'} rounded>
        <FText h7 grey>
          **** **** **** {last4}
        </FText>
      </FView>
      <FView size={'6rem'} center pl="1.5rem">
        {EditCCButtonElement}
      </FView>
    </FView>
  </>
)

export default UserDetailsPaymentView
