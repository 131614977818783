import React, { useContext } from 'react'
import { FView } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'

export default function Card({ children, className, ...props }) {
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView className={className} bg={themeColors.white} shadow25 rounded p="4rem" {...props}>
      {children}
    </FView>
  )
}
