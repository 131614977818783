import React, { useContext } from 'react'
import { FText, FView, Space } from 'components'
import { ThemeContext } from 'f-web-app'

const OrderCompleteView = ({
  orderNumber,
  OrderStatusElement,
  status,
  isFoodReady,
  deliveryTime,
  orderType,
  completionTime,
  estimatedCompletionTime,
  pointsEarned,
  cancelReason,
}) => {
  const { themeColors } = useContext(ThemeContext)

  return (
    <FView fill maxWidth="100%" bg={themeColors.white} p={'2rem'} rounded shadow25>
      <FText h4 cardText textCenter>
        Order {`#${orderNumber ? orderNumber : ''}`}
      </FText>
      <Space h3 />
      {OrderStatusElement}
      <Space h3 />
    </FView>
  )
}

export default OrderCompleteView
