import { useState, useEffect } from 'react'

const TRIGGER_POINT = 5

export default function useIsTop() {
  const [isTop, setIsTop] = useState(true)
  useEffect(() => {
    const handleScroll = e => {
      if (window.scrollY > TRIGGER_POINT) {
        setIsTop(false)
      } else {
        setIsTop(true)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return isTop
}
