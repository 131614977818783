import React, { useState, useContext } from 'react'
import { dispatch } from 'f-web-app/store'
import { ThemeContext } from 'f-web-app'

export default function Menu() {
  const { Menu } = useContext(ThemeContext)
  const [categoryId, setCategoryId] = useState('')
  if (!categoryId && dispatch.restaurant.getActiveCategoryOrder()[0]) {
    setCategoryId(dispatch.restaurant.getActiveCategoryOrder()[0])
  }
  return <Menu.MenuView />
}
