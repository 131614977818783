import React, { useContext } from 'react'
import { FText, FView } from 'components'
import { useMediaMinMD } from 'f-web/src/hooks'
import { css } from 'emotion'
import { ThemeContext } from 'f-web-app'

const OrderHistoryView = ({ OrdersListElement, OrderDetailsModalElement }) => {
  const isMinMD = useMediaMinMD()
  const { themeColors } = useContext(ThemeContext)
  return (
    <FView>
      <FText bold h5 h4={isMinMD} black style={{ margin: '.5rem' }}>
        Order History
      </FText>
      <FView h={1} mb={'2.5rem'} bg={themeColors.grey} />
      <div className={styles.orders}>{OrdersListElement}</div>
      {OrderDetailsModalElement}
    </FView>
  )
}

const styles = {
  orders: css({
    height: '70vh',
    overflow: 'auto',
  }),
}

export default OrderHistoryView
